import FormControl from "@mui/material/FormControl"
import OutlinedInput from "@mui/material/OutlinedInput"
import Typography from "@mui/material/Typography"
import InputAdornment from "@mui/material/InputAdornment"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Tooltip from "@mui/material/Tooltip"
import InfoIcon from "@mui/icons-material/Info"
import IconButton from "@mui/material/IconButton"
import defaultAprops from "../../constants/hideInputArrows"

/**
 * 
 * @param {{_id: number, val: string|number, label: string, options: Array<{val: number, label: string}>}} param0 
 * @returns 
 */
function TooltipCanvasInput({id,val,label,tooltip,err,sx,end,type="text",disabled,onInput}){
	let aSx = {}
	if(type === "number") aSx = defaultAprops.number
	return <FormControl size="small" sx={{...sx,...aSx}}>
		<InputLabel htmlFor={id} error={err !== undefined}>{label}</InputLabel>
		<OutlinedInput disabled={disabled} error={err !== undefined}
		value={val} onInput={onInput} id={id} type={type} label={label}
		endAdornment={<InputAdornment position="end">{end}</InputAdornment>}/>
		<Tooltip title={tooltip} enterTouchDelay={0} leaveTouchDelay={10000}><IconButton sx={{position:"absolute",right:-40}}><InfoIcon/></IconButton></Tooltip>
		<Typography
			sx={{userSelect:"none",pointerEvents:"none",position:"absolute",bottom:"-1rem",left:"1rem",opacity: err !== undefined ? 1 : 0}}
			component="b" align="left" fontSize="small" color="error">{err}</Typography>
	</FormControl>
}
export default TooltipCanvasInput