import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel";
import CircularProgress from '@mui/material/CircularProgress'
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import { useContext, useEffect, useState } from "react";
import { Context } from "../../contexts/general";
import http from "../../libs/http";
import { useNavigate, redirect } from "react-router-dom";
import Paper from "@mui/material/Paper";
function Register(){
	const [user,setUser] = useState("")
	const [firstname,SetFirstname] = useState("")
	const [lastname,SetLastname] = useState("")
	const [phone,setPhone] = useState("")
	const [address,setAddress] = useState("")
	const [loading,setLoading] = useState(false)
	const [done,setDone] = useState(false)
	const [err,setErr] = useState()
	const {auth} = useContext(Context)
	const navigate = useNavigate()
	const create = () => {
		setLoading(true)
		setErr()
		const body = {
			fname: firstname,
			lname: lastname,
			addr: address,
			phn: phone
		}
		if(user.includes("@")) body.eml = user
		else body.usr = user
		http.post({url:"users/client/create",body,auth}).then(res=>{
			console.log(res)
			if(res.data){
				if(res.data.error){
					setLoading(false)
					return setErr(res.data.error.message + ": " + res.data.error.data.message)
				}
				if(res.status !== 200){
					setLoading(false)
					if(res.status === 404 && res.statusText === "Not Found") return setErr("No se pudo conectar al servidor")
					return setErr(`${res.status} ${res.statusText}`)
				}
				setDone(true)
			}
			setLoading(false)
		})
	}
	if(done) return <Box sx={{transition: "0.2s linear",height:"100%",my:"auto",boxSizing:"border-box",justifyContent:"center",display:"flex",flexDirection:"column",p:2
		,"> *":{mb:2}
	}}><Typography>Registro finalizado</Typography>
	<Typography>El cliente deberá revisar su correo para confirmar su cuenta</Typography>
	<Button onClick={()=>{setDone(false);setLoading(false);setErr();setUser("");SetFirstname("");SetLastname("");setPhone("");setAddress("")}} sx={{mt:2,position:"relative",width:"max-content",mx:"auto"}} variant="contained">
			Registrar otro usuario
		</Button>
	</Box>
	return <Box sx={{transition: "0.2s linear",height:"100%",my:"auto",boxSizing:"border-box",justifyContent:"center",display:"flex",flexDirection:"column",p:2
		
	}}><Box component={Paper} sx={{boxSizing:"border-box",justifyContent:"center",display:"flex",flexDirection:"column",p:2,"> *":{mb:2}}}>
		<Typography sx={{color:"rgba(0,0,0,0.6)",fontSize:"1.5rem",pointerEvents:"none",userSelect:"none"}} component="b">
			Registrar cliente
		</Typography>
		<TextField disabled={loading}
			id="user" label="Correo" type="email" variant="standard"
			value={user} onInput={(ev)=>{setUser(ev.target.value)}}/>
		<TextField disabled={loading}
			id="fname" label="Nombres" type="text" variant="standard"
			value={firstname} onInput={(ev)=>{SetFirstname(ev.target.value)}}/>
		<TextField disabled={loading}
			id="lname" label="Apellidos" type="text" variant="standard"
			value={lastname} onInput={(ev)=>{SetLastname(ev.target.value)}}/>
		<TextField disabled={loading}
			id="phone" label="Teléfono" type="phone" variant="standard"
			value={phone} onInput={(ev)=>{setPhone(ev.target.value)}}/>
		<TextField disabled={loading}
			id="address" label="Dirección" type="text" variant="standard"
			value={address} onInput={(ev)=>{setAddress(ev.target.value)}}/>
		<Button disabled={loading} onClick={create} sx={{mt:2,position:"relative",width:"max-content",mx:"auto"}} variant="contained">
			Registrar
			{loading && <CircularProgress sx={{mx:"auto",position:"absolute"}}/>}
		</Button>
		{err && <Typography mt={1} color="error">{err}</Typography>}
		</Box></Box>
}
export default Register