import {Component} from "react"
import CircularWithValueLabel from "../../basicComps/labeledCircularProgress"
import ImageLoader from "./imageLoader"

class ImageComponent extends Component{
	constructor(props){
		super(props)
		this.state = {loaded:0, progress: 0, file: props.file, url: props.url, data: props.data, loader: undefined, updateImage: props.updateImage}
	}
	setProgress = (value) => {
		this.setState({progress: value})
	}
	componentDidMount(){
		if(this.props.loader) return
		//console.log({state: this.state})
		let imageLoader
		if(this.state.url){
			console.log("urlMode")
			imageLoader = new ImageLoader(this.state.url,this.setProgress,this.props.onLoaded)
			this.props.data.type = "url"
		}else{
			console.log("fileMode")
			imageLoader = new ImageLoader(this.state.file,this.setProgress,this.props.onLoaded)
			this.props.data.type = "file"
		}
		imageLoader.copies = this.props.data.qty
		this.props.setLoader(imageLoader)
		this.props.data.loader = imageLoader
		this.props.data.setProgress = this.setProgress
		//console.log("Loader set.")
		//this.setState({loader:imageLoader})
	}
	render(){
		const loader = this.props.loader
		/*if(!loader && this.state.progress === 100){
			let newState = {progress: 0, loaded: 0}
			let imageLoader
			if(this.props.url){
				newState.url = this.props.url
				imageLoader = new ImageLoader(newState.url,this.setProgress,this.props.onLoaded)
			}else if(this.props.file){
				newState.file = this.props.file
				imageLoader = new ImageLoader(newState.file,this.setProgress,this.props.onLoaded)
			}
			this.props.setLoader(imageLoader)
			this.props.data.loader = imageLoader
			this.setState(newState)
		}*/
		if(loader && this.state.progress === 100) return <img src={loader.url} alt="" onLoad={()=>{URL.revokeObjectURL(loader.url)}}/>
		return <CircularWithValueLabel value={this.state.progress}/>
	}
}
export default ImageComponent;