function rowBlank(imageData, width, y){
	for(let x = 0; x < width; ++x){
		if(imageData.data[y * width * 4 + x * 4 + 3] !== 0) return false
	}
	return true
}
function columnBlank(imageData, width, x, top, bottom) {
	for(let y = top; y < bottom; ++y) {
		if (imageData.data[y * width * 4 + x * 4 + 3] !== 0) return false
	}
	return true
}

class ImageLoader{
	constructor(file,setProgress,onLoaded){
		this.originalFile = file
		console.log({file})
		console.log("typeof file:", typeof file)
		this.img = new Image()
		if(typeof file === "string"){
			this.url = file
			this.img.crossOrigin = "Anonymous"
			this.type = "url"
		}else{
			/*let ffile = file
			ffile.text().then(str=>{console.log({fileStr:str})})
			console.log(file)*/
			this.type = "file"
			if(file.type !== "image/png") this.file = file
			this.url = URL.createObjectURL(file)
		}
		this.setProgress = setProgress
		this.onLoaded = onLoaded
		this.img.onload = this.onload.bind(this)
		this.img.src = this.url
		this.state = 0
	}
	onload(){
		console.log("img data:")
		console.log(this.img.getBoundingClientRect())
		console.log(this.img.getClientRects())
		if(this.file && !this.onLoadCb){
			this.state = 2
			this.onLoaded(this.img.width,this.img.height)
			this.width = this.img.width
			this.height = this.img.height
			this.widthProportion = 100
			this.heightProportion = 100
			this.setProgress(100)
			return
		}
		let canvas = document.createElement("canvas")
		let context = canvas.getContext("2d")
		canvas.width = this.img.width
		canvas.height = this.img.height
		let width = canvas.width
		context.drawImage(this.img,0,0)
		console.log({typeofurl: typeof this.url})
		if(this.type === "url" && !this.onLoadCb){ // No cropping for images loaded from API, as it already crops.
			//this.onLoaded(this.img.width,this.img.height)
			this.setProgress(50)
			this.state = 1
			canvas.toBlob((blob)=>{
				this.file = blob
				this.state = 2
				this.onLoaded(this.img.width,this.img.height, blob.size)
				this.width = this.img.width
				this.height = this.img.height
				this.widthProportion = 100
				this.heightProportion = 100
				this.setProgress(100)
			})
			return
		}
		let imageData = context.getImageData(0,0,canvas.width,canvas.height)
		this.setProgress(10)
		let top = 0, bottom = imageData.height, left = 0, right = imageData.width
		while(top < bottom && rowBlank(imageData,width,top)) ++top
		this.setProgress(20)
		while(bottom - 1 > top && rowBlank(imageData, width, bottom - 1)) --bottom
		this.setProgress(40)
		while(left < right && columnBlank(imageData, width, left, top, bottom)) ++left
		this.setProgress(60)
		while(right - 1 > left && columnBlank(imageData, width, right - 1, top, bottom)) --right
		this.setProgress(80)
		let trimmed = context.getImageData(left,top,right-left,bottom-top)
		canvas.width = right-left
		canvas.height = bottom-top
		context.putImageData(trimmed,0,0)
		this.setProgress(90)
		this.updateImage(canvas)
	}
	updateImage(canvas){
		if(this.type === "file") URL.revokeObjectURL(this.url)
		this.url = canvas.toDataURL()
		this.width = canvas.width
		this.height = canvas.height
		canvas.toBlob((blob)=>{
			console.log({blob})
			this.file = blob
			this.state = 1 // Trimmed image, waiting to load it.
			this.img.onload = this.onLoadTrimmed.bind(this)
			this.setProgress(95)
			this.img.src = this.url
		})
	}
	/**
	 * Update image with other url
	 */
	update(file,cb){
		this.originalFile = file
		//console.log({file})
		//console.log("typeof file:", typeof file)
		this.img = new Image()
		//if(typeof file === "string"){
		this.url = file
		//this.img.crossOrigin = "Anonymous"
		this.type = "url"
		this.state = 0
		this.onLoadCb = cb
		this.img.onload = this.onload.bind(this)
		this.img.src = file
		this.setProgress(95)
	}
	onLoadTrimmed(){
		this.state = 2
		this.onLoaded(this.img.width,this.img.height,this.file.size)
		this.width = this.img.width
		this.height = this.img.height
		this.widthProportion = 100
		this.heightProportion = 100
		if(this.onLoadCb){
			this.onLoadCb()
			delete this.onLoadCb
		}
		this.setProgress(100)
	}
	onRemove(){
		//URL.revokeObjectURL(this.url)
		//this.url = undefined
	}
}
export default ImageLoader