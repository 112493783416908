import { useState } from "react"
import CircularProgress from "@mui/material/CircularProgress"

/**
 * 
 * @param {{src: String, size: [Number, Number]}} param0 
 * @returns 
 */
function TableImage({src,size}){
	const [state,setState] = useState(0)
	if(typeof size[0] === "number") size[0] = `${size[0]}px`
	if(typeof size[1] === "number") size[1] = `${size[1]}px`

	return <div style={{width:size[0],height:size[1]}}>
		{state === 0 && <CircularProgress/>}
		<img src={src} width={size[0]} height={size[1]}
			onLoad={()=>{
				setState(1)
			}}
			onError={(ev)=>{
				ev.target.src = "/404.png"
			}}
			alt=""/>
	</div>
}

export default TableImage;