import CircularProgress from "@mui/material/CircularProgress"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Paper from "@mui/material/Paper"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from "@mui/icons-material/Delete"
import DownloadIcon from "@mui/icons-material/Download"
import PendingIcon from "@mui/icons-material/HistoryToggleOff"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import SyncIcon from "@mui/icons-material/Sync"
import EditIcon from "@mui/icons-material/Edit"
import ItemRow from "./itemRow"
import TableImage from "../basicComps/tableImage"
import http from "../../libs/http"
import localizeDate from "../scripts/localizeDate"
import ms from "../constants/measurement"
import { useContext, useEffect, useState } from "react"
import Button from "@mui/material/Button"
import { Context } from "../../contexts/general"
import { useNavigate } from "react-router-dom"

function CanvasRow({val,idx,onDelete,getStateStr,noName,noDesc,sticky,onUpdate}){
	const [viewItems,setViewItems] = useState(false)
	/**
	 * @type {[Array<{id:Number,name:String,rid:Number,w:Number,h:Number,state:Number,qty:Number}>,Function]}
	 */
	const [items,setItems] = useState()
	const [status,setStatus] = useState({pending: 0, complete: 0, total: val.meta.imgsQty})
	const [loading,setLoading] = useState(false)
	const [modal,setModal] = useState()
	const {auth} = useContext(Context)
	const navigate = useNavigate()
	const closeModal = () => {setModal()}

	const onUpload = (_idx,filename) => {
		let item = items[_idx]
		item.state = 1
		item.name = filename
		setItems([...items])
		setStatus({pending: status.pending-1, complete: status.complete+1, total: status.total})
		if(status.complete+1 === status.total){ // Try to finish the process.
			http.post({url:"rectpack/finish_canvas",body:{_id: val._id},auth}).then(res=>{
				if(res.status === 200) onUpdate(idx,1)
				else console.error(res)
			})
		}
	}

	const onDeleteItem = (_idx) => {
		let item = items[_idx]
		item.state = 0
		item.name = false
		setItems([...items])
		setStatus({pending: status.pending+1, complete: status.complete-1, total: status.total})
		if(status.complete === status.total){
			onUpdate(idx,0)
		}
	}

	useEffect(()=>{
		if(viewItems && !items && loading){
			http.get({url:`rectpack/list_items/${val._id}`,auth}).then(res=>{
				if(res.status === 200){
					setItems(res.data)
					let pending = 0, complete = 0
					for(let item of res.data){
						if(item.state === 0) pending++
						else complete++
					}
					setStatus({pending, complete, total: status.total})
				}
				setLoading(false)
			})
		}
	})

	return <>
		<Modal open={modal ? true : false}
			onClose={closeModal}>
				<Paper sx={{
					position:"absolute",
					top:"50%",
					left:"50%",
					transform: "translate(-50%,-50%)",
					width:"max-content",
					display:"flex",
					flexDirection:"column",
					alignItems:"center",
					p:3
				}}>
					<Typography fontSize="1.5rem">¿Eliminar {modal?.model} {modal?._id}?</Typography>
					<Box sx={{display:"flex",flexDirection:"row",width:"100%",mt:1,justifyContent:"space-evenly"}}>
						<Button variant="outlined" color="inherit" sx={{opacity:0.7, mr:3}}
							onClick={closeModal}>Cancelar</Button>
						<Button variant="contained" color="error"
							onClick={()=>{modal?.cb();closeModal()}}>Confirmar</Button>
					</Box>
				</Paper>
		</Modal>
		<TableRow>
			<TableCell sx={sticky}>
				<Box sx={{display:"flex",flexDirection:"column",alignItems:"center"}}>
					<Tooltip title={`ID: ${val._id}`} placement="top" arrow>
						<Box>{val.state === 0 ? <PendingIcon sx={{fontSize:"64px",color:"rgba(0,0,0,0.54)"}}/> : <TableImage src={`${http.url}rectpack/canvas_img/${val._id}?web=1`} size={[64,64]}/>}</Box>
					</Tooltip>
				</Box>
			</TableCell>
			<TableCell>
				<Box sx={{display:"flex",flexDirection:"row",width:"fit-content",mx:"auto"}}>
					<IconButton size="small" color="primary" onClick={()=>{
						setViewItems(!viewItems)
						if(!items) setLoading(true)
					}}>{viewItems ? <VisibilityOffIcon/> : <VisibilityIcon/>}</IconButton>
					{val.state === 1 && <IconButton size="small" color="primary" href={`${http.url}rectpack/canvas_img/${val._id}`}><DownloadIcon/></IconButton>}
					<IconButton size="small" color="primary" onClick={()=>{ // Edit
						navigate("../create",{state:{_id: val._id}})
					}}><EditIcon/></IconButton>
					<IconButton size="small" onClick={()=>{
						setModal({_id: val._id, cb: onDelete, model: "trabajo"})
					}}><DeleteIcon/></IconButton>
				</Box>
			</TableCell>
			<TableCell>{val.name ? val.name : noName}</TableCell>
			<TableCell>{val.desc ? val.desc : noDesc}</TableCell>
			<TableCell>{val.usr ? (val.usr.fname ? (val.usr.fname + " " + val.usr.lname) : val.usr) : val.phn ? val.phn : "-"}</TableCell>
			<TableCell>{getStateStr(val.state)}</TableCell>
			<TableCell>{val.mode === 0 ? "Múltiples imágenes" : "Única imágen repetida"}</TableCell>
			<TableCell>{(val.w * ms.pxToCm).toFixed(2)}</TableCell>
			<TableCell>{(val.h * ms.pxToCm).toFixed(2)}</TableCell>
			<TableCell>{localizeDate(val.cAt," @")}</TableCell>
			<TableCell>{localizeDate(val.wAt ? val.wAt : val.cAt," @")}</TableCell>
		</TableRow>
		{viewItems && <TableRow>
			<TableCell colSpan={10}>
				{loading ? <CircularProgress/> :
				<TableContainer sx={{position:"relative"}}>
					{val.state === 0 && (status.complete === status.total) && <IconButton color="primary" sx={{position:"absolute",left:"0.5rem",top:"0.5rem"}} onClick={()=>{
						http.post({url:"rectpack/finish_canvas",body:{_id: val._id},auth}).then(res=>{
							if(res.status === 200) onUpdate(idx,1)
							else console.error(res)
						})
					}}><SyncIcon/></IconButton>}
					<Table>
						<TableHead>
							<TableRow>
								<TableCell colSpan={8}>
									<Box sx={{display:"flex",flexDirection:"row",justifyContent:"space-evenly"}}>
										<Box sx={{display:"flex",flexDirection:"column",alignItems:"center"}}>
											<Typography component="b" fontWeight={500}>Pendiente</Typography>
											<Typography component="span">{status.pending}</Typography>
										</Box>
										<Box sx={{display:"flex",flexDirection:"column",alignItems:"center"}}>
											<Typography fontWeight={500}>Completado</Typography>
											<Typography component="span">{status.complete}</Typography>
										</Box>
										<Box sx={{display:"flex",flexDirection:"column",alignItems:"center"}}>
											<Typography fontWeight={500}>Total</Typography>
											<Typography component="span">{status.total}</Typography>
										</Box>
									</Box>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Item</TableCell>
								<TableCell>Opciones</TableCell>
								<TableCell>Nombre</TableCell>
								<TableCell>Nº</TableCell>
								<TableCell>Estado</TableCell>
								<TableCell>Cantidad</TableCell>
								<TableCell>Ancho</TableCell>
								<TableCell>Alto</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{items && items.map((val,_idx)=>(
								<ItemRow key={`trow${idx}_img${_idx}`} val={val} idx={_idx} getStateStr={getStateStr} noName={noName}
									onUpload={onUpload} onDelete={[setModal,onDeleteItem]}/>
							))}
						</TableBody>
					</Table>
				</TableContainer>}
			</TableCell>
		</TableRow>}
	</>
}

export default CanvasRow