import Box from "@mui/material/Box"
import { useContext, useEffect, useState } from "react"
import http from "../../libs/http"
import { Context } from "../../contexts/general"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import ms from "../constants/measurement"
import { Divider } from "@mui/material"

const options = [{val:Math.ceil(28.5*ms.cmToPx),label:"28,5 cm (DTF)"},
{val:Math.ceil(31.5*ms.cmToPx),label:"31,5 cm (DTF)"},
{val:Math.ceil(58.5*ms.cmToPx),label:"58,5 cm (DTF)"},
{val:Math.ceil(61.5*ms.cmToPx),label:"61,5 cm (DTF)"},
{val:Math.ceil(107*ms.cmToPx),label:"107 cm (Sublimación)"},
{val:Math.ceil(157*ms.cmToPx),label:"157 cm (Sublimación)"},
{val:Math.ceil(160*ms.cmToPx),label:"160 cm (Sublimación)"}]

function Pricing(){
	const [pricing,setPricing] = useState(undefined)
	const [create,setCreate] = useState(0)
	const {auth} = useContext(Context)
	useEffect(()=>{
		if(pricing === undefined){
			http.get({url:"rectpack/pricing/get",auth}).then(res=>{
				if(res.status === 200) return setPricing(res.data)
				if(res.data.msg === "Not found") setPricing(null)
			})
		}
	},[pricing])
	function getPricingList(){
		const list = {}
		const elems = []
		let i = 0
		for(let val of options){
			const price = pricing.list[val.val]
			elems.push(<Box key={`price${i++}`} sx={{mb:2}}>
				<Typography>Precio para {val.label}</Typography>
				<TextField disabled={create !== 1} id={`${val.val}.h`} label="Por largo" type="number" size="small" value={price?.h ? price.h : ""}/>
				<TextField disabled={create !== 1} id={`${val.val}.img`} label="Por imágen" type="number" size="small" value={price?.img ? price.img : ""}/>
			</Box>)
		}
		return elems
	}
	return <Box sx={{display:"flex", width:"100%", height:"100%", justifyContent:"center", alignItems:"center", flexDirection: "column"}}>
		<Box component={Paper} sx={{p:3}}>
		{pricing && getPricingList()}
		{pricing === null && create === 0 && <>
			<Typography>Aún no has creado una lista de precios</Typography>
			<Button variant="contained" onClick={()=>{
				setCreate(1)
			}}>Crear</Button>
		</>}
		{create === 1 && <>
			{options.map((val,idx)=>{
				return <Box key={`price${idx}`} sx={{mb:2}}>
					<Typography>Precio para {val.label}</Typography>
					<TextField disabled={create !== 1} id={`${val.val}.h`} label="Por largo" type="number" size="small"/>
					<TextField disabled={create !== 1} id={`${val.val}.img`} label="Por imágen" type="number" size="small"/>
				</Box>
			})}
			<Button variant="contained" onClick={()=>{
				const list = {}
				for(let val of options){
					const h = document.getElementById(`${val.val}.h`).value
					const img = document.getElementById(`${val.val}.img`).value
					list[val.val] = {h: parseFloat(h), img: parseFloat(img)}
				}
				setCreate(2)
				http.post({url:"rectpack/pricing/create",auth,body:{
					list
				}}).then(res=>{
					setCreate(0)
					setPricing(undefined)
				})
			}}>{pricing ? "Modificar" : "Crear"}</Button>
		</>}
	</Box></Box>
}
export default Pricing