import CircularProgress from "@mui/material/CircularProgress"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from "@mui/icons-material/Delete"
import DownloadIcon from "@mui/icons-material/Download"
import PendingIcon from "@mui/icons-material/HistoryToggleOff"
import UploadIcon from "@mui/icons-material/Upload"
import VisuallyHiddenInput from "../basicComps/visuallyHiddenInput"
import TableImage from "../basicComps/tableImage"
import http from "../../libs/http"
import ms from "../constants/measurement"
import { useContext, useState } from "react"
import { Context } from "../../contexts/general"

function ItemRow({val,idx,getStateStr,noName,onUpload,onDelete}){
	const [loading,setLoading] = useState(false)
	const {auth} = useContext(Context)
	return <TableRow>
		<TableCell>
			<Box sx={{display:"flex",flexDirection:"column",alignItems:"center"}}>
				<Tooltip title={`ID: ${val._id.n}-${val._id.cid}`} placement="top" arrow>
					<Box>{val.state === 0 ?
						<PendingIcon sx={{fontSize:"64px",color:"rgba(0,0,0,0.54)"}}/> :
						<TableImage src={`${http.url}rectpack/item_img/${val._id.n}-${val._id.cid}?web=1`} size={[64,64]}/>
					}</Box>
				</Tooltip>
			</Box>
		</TableCell>
		<TableCell>
			<Box sx={{display:"flex",flexDirection:"row",width:"fit-content",mx:"auto"}}>
				{loading && <CircularProgress/>}
				{!loading && val.state === 0 && <IconButton size="small" color="primary" component="label">
					<UploadIcon/>
					<VisuallyHiddenInput type="file" accept="image/png,image/jpeg,image/bmp,image/tiff"/*,image/webp"*/ onInput={(ev)=>{
						setLoading(true)
						const file = ev.target.files[0]
						if(!file) return;
						const body = new FormData()
						body.append("n",val._id.n)
						body.append("cid",val._id.cid)
						body.append("img",file,file.name)
						http.post({url:"rectpack/add_img",body,auth}).then(res=>{
							if(res.status === 200){
								onUpload(idx,file.name)
							}else{
								console.error(res)
							}
							setLoading(false)
						})
					}}/>
				</IconButton>}
				{!loading && val.state === 1 && <IconButton size="small" color="primary" href={`${http.url}rectpack/item_img/${val._id.n}-${val._id.cid}`}><DownloadIcon/></IconButton>}
				{!loading && val.state === 1 && <IconButton size="small" onClick={()=>{
					onDelete[0]({_id: val._id.n, model: "imagen", cb: ()=>{
						setLoading(true)
						http.post({url:"rectpack/remove_img",body:{_id: val._id}, auth}).then(res=>{
							if(res.status === 200){
								onDelete[1](idx)
							}else{
								console.error(res)
							}
							setLoading(false)
						})
					}})
				}}>
					<DeleteIcon/>
				</IconButton>}
			</Box>
		</TableCell>
		<TableCell>{val.name ? val.name : noName}</TableCell>
		<TableCell>{val._id.n}</TableCell>
		<TableCell>{getStateStr(val.state)}</TableCell>
		<TableCell>{val.qty}</TableCell>
		<TableCell>{(val.w * ms.pxToCm).toFixed(2)}</TableCell>
		<TableCell>{(val.h * ms.pxToCm).toFixed(2)}</TableCell>
	</TableRow>
}

export default ItemRow