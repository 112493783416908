import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function LabeledCircularProgress(props) {
	return (
	<Box sx={{ position: 'relative', display: 'inline-flex' }}>
		<CircularProgress variant="determinate" value={props.value}/>
		<CircularProgress sx={{top:0,left:0,position:"absolute",opacity:0.5}} variant="determinate" value={props.buffer}/>
		<Box
		sx={{
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			position: 'absolute',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		}}
		>
		<Typography variant="caption" component="div" color="text.secondary">
			{`${Math.round(props.value)}%`}
		</Typography>
		</Box>
	</Box>
	);
}

LabeledCircularProgress.propTypes = {
	/**
	 * The value of the progress indicator for the determinate variant.
	 * Value between 0 and 100.
	 * @default 0
	 */
	value: PropTypes.number.isRequired,
};

export default function CircularWithValueLabel({value,buffer}) {
	//const [progress, setProgress] = useState(0);

	/*useEffect(() => {
	const timer = setInterval(() => {
		setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
	}, 800);
	return () => {
		clearInterval(timer);
	};
	}, []);*/

	return <LabeledCircularProgress value={value} buffer={buffer}/>;
}