import { forwardRef } from "react"
import styled from "@mui/material/styles/styled"

const StyledCanvas = styled("canvas")({
	height: "auto",
	boxSizing: "border-box",
	display: "block",
	margin: "auto"
})

const Canvas = forwardRef(function(props,ref){
	return <StyledCanvas ref={ref} {...props}/>
})

export default Canvas