import { useEffect, useState } from "react"
import ScheduleIcon from "@mui/icons-material/Schedule"
import CircularProgress from "@mui/material/CircularProgress"
import CheckIcon from "@mui/icons-material/Check"
import ReplayIcon from "@mui/icons-material/Replay"
import IconButton from "@mui/material/IconButton"
import http from "../../../libs/http"

const states = [<ScheduleIcon color="primary"/>,<CircularProgress size="1.5rem"/>,<CheckIcon color="primary"/>,<ReplayIcon color="warning"/>]

function UploadingImage({_id,file,url,name,onUpload,temp,auth}){
	const [state,setState] = useState(0)
	const [tries,setTries] = useState(0)
	useEffect(()=>{
		if(state === 0){
			setState(1)
			let body
			if(file){
				body = new FormData()
				body.append("n",_id.n)
				body.append("cid",_id.cid)
				body.append("img",file,name)
				if(temp){
					body.append("tid",temp.tid)
					body.append("sid",temp.sid)
				}
			}else{
				body = {
					n: _id.n,
					cid: _id.cid,
					url
				}
				if(temp){
					body.tid = temp.tid
					body.sid = temp.sid
				}
			}
			http.post({url:"rectpack/add_img",body,auth}).then(res=>{
				if(res.status === 200){
					onUpload()
					return setState(2)
				}
				if(tries < 5){
					setTries((tries)=>tries + 1)
					return setState(0)
				}
				return setState(3)
			})
		}
	}, [state, _id, file, name, onUpload, tries, temp, url, auth])
	if(state === 3){
		return <IconButton size="small" onClick={()=>{setState(0)}}>
			{states[state]}
		</IconButton>
	}
	return states[state]
}
export default UploadingImage