import FormControl from "@mui/material/FormControl"
import OutlinedInput from "@mui/material/OutlinedInput"
import Typography from "@mui/material/Typography"
import InputAdornment from "@mui/material/InputAdornment"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import EditIcon from "@mui/icons-material/Edit"
import IconButton from "@mui/material/IconButton"
import defaultAprops from "../../constants/hideInputArrows"
import {useState} from "react"

/**
 * 
 * @param {{_id: number, val: string|number, label: string, options: Array<{val: number, label: string}>}} param0 
 * @returns 
 */
function EditableCanvasInput({id,val,label,err,sx,end,type="text",options,onInput}){
	const [edit,setEdit] = useState(()=>{
		if(!val) return false
		for(let option of options){
			if(val === option.val){
				return false
			}
		}
		return true
	})
	let aSx = {}
	if(type === "number") aSx = defaultAprops.number
	return <FormControl size="small" sx={{...sx,...aSx}}>
		<InputLabel htmlFor={id} error={err !== undefined}>{label}</InputLabel>
		{!edit ? <Select value={val} onChange={onInput} id={id} label={label} sx={{textAlign:"left"}}>
			{options.map((val,idx)=><MenuItem key={`canvasWOpt${idx}`} value={val.val}>{val.label}</MenuItem>)}
		</Select> : <OutlinedInput error={err !== undefined}
		value={val} onInput={onInput} id={id} type={type} label={label}
		endAdornment={<InputAdornment position="end">{end}</InputAdornment>}/>}
		<IconButton sx={{position:"absolute",right:-40}} onClick={()=>{setEdit(!edit)}}><EditIcon/></IconButton>
		<Typography
			sx={{userSelect:"none",pointerEvents:"none",position:"absolute",bottom:"-1rem",left:"1rem",opacity: err !== undefined ? 1 : 0}}
			component="b" align="left" fontSize="small" color="error">{err}</Typography>
	</FormControl>
}
export default EditableCanvasInput