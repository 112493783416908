import { useState } from 'react'
import Paper from '@mui/material/Paper'
import Box from "@mui/material/Box"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import LockIcon from "@mui/icons-material/Lock"
import LockOpenIcon from '@mui/icons-material/LockOpen'
import CropIcon from "@mui/icons-material/Crop"
import DeleteIcon from "@mui/icons-material/Delete"
import LinkIcon from "@mui/icons-material/Link"
import LinkOffIcon from "@mui/icons-material/LinkOff"
import CheckIcon from "@mui/icons-material/Check"
import styled from "@mui/material/styles/styled"
import DividerMui from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import ImageComponent from './components/image'
import TextField from '@mui/material/TextField'
import InputAdornment from "@mui/material/InputAdornment"
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import CircularProgress from "@mui/material/CircularProgress"
import ContainedIconButton from '../basicComps/containedIconButton'
import { isMobile } from 'react-device-detect'
import ms from '../constants/measurement'
import localizeDate from '../scripts/localizeDate'
import ImageLoader from "./components/imageLoader"
import http from '../../libs/http'

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}))
const IconButton = ContainedIconButton
const Divider = styled(DividerMui)({
	width:"calc(100% + 1rem)",
	marginLeft:"-0.5rem"
})
const Input = styled(TextField)({
	"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
})
function minifySize(size){
	if(size >= 1048576){ // mB
		return `${(size/1048576).toPrecision(3)} MB`
	}
	if(size >= 1024){ // kB
		return `${(size/1024).toPrecision(3)} KB`
	}
	return `${size} B`
}
const inputSx = {my:1,display:"flex",alignItems:"center",justifyContent:"center"}
function InputNumber({label,tag,onChange,value,disabled}){
	return <Input sx={{width:"100%"}}
		disabled={disabled} type="number" size="small" label={label} onChange={onChange} value={value}
		InputProps={{endAdornment:<InputAdornment sx={{pointerEvents:"none",cursor:"none",userSelect:"none"}} position="end" component="span">{tag}</InputAdornment>}}/>
}
function withDecimals(value,decimals=2){
	let zeros = Math.pow(10,decimals)
	return Math.round((value + Number.EPSILON) * zeros) / zeros 
}
function ImageItem({data,onRemove,onToggleCrop,onToggleBgRemover,auth,tid,sid,disable}){
	//console.log({dataT: data})
	const file = data.file
	const url = data.url
	const name = data.name ? data.name : file.name
	const key = url ? `${data._id.n}-${data._id.cid}` : `${file.lastModified}_${file.size}_${name}`
	const [locked,setLocked] = useState(true)
	const [originalWidth,setOriginalWidth] = useState("")
	const [originalHeight,setOriginalHeight] = useState("")
	const [width,setWidth] = useState(originalWidth)
	const [height,setHeight] = useState(originalHeight)
	const [widthProportion,setWidthProportion] = useState("")
	const [heightProportion,setHeightProportion] = useState("")
	const [copies,setCopies] = useState("")
	const [size,setSize] = useState(file?.size)
	const [loader,setLoader] = useState()
	const [showCopies,setShowCopies] = useState(false)
	const [enha,setEnha] = useState(0)
	const [nobg,setNobg] = useState(0)
	const [err,setErr] = useState()
	const [status,setStatus] = useState()
	const [loading,setLoading] = useState(false)
	const apiSetters = {nobg: setNobg, enha: setEnha}
	const lastModified = localizeDate(file?.lastModified)
	function isLoaded(){
		if(!loader) return false
		if(loader && loader.state < 2) return false
		return true
	}
	const toggleCopies = () => {
		if(!copies){
			setCopies(1)
			if(disable && disable.disableHeight) disable.setDisableHeight(false)
		}
		setShowCopies(!showCopies)
	}
	const onLoaded = (width,height,size) => {
		setOriginalWidth(width)
		setOriginalHeight(height)
		if(data.w){
			setWidth(withDecimals(data.w * ms.pxToCm))
			setWidthProportion(((data.w / width) * 100))
		}else{
			setWidth(withDecimals(width * ms.pxToCm))
			setWidthProportion(100)
		}
		if(data.h){
			setHeight(withDecimals(data.h * ms.pxToCm))
			setHeightProportion((data.h / height) * 100)
		}else{
			setHeight(withDecimals(height * ms.pxToCm))
			setHeightProportion(100)
		}
		if(size) setSize(size)
		if(data.qty){
			setCopies(data.qty)
		}
		else if(!copies) setCopies(1)
	}
	const updateCopies = (ev) => {
		let val = parseInt(ev.target.value)
		console.log("updating copies with val",val)
		if(!isNaN(val) && val <= 0) val = 1
		else if(val > 9999) val = 9999
		setCopies(val)
		if(disable){
			console.log("disabling with value",val,"and dd",disable)
			if(val === 1 || isNaN(val)) disable.setDisableHeight(false)
			else if(!disable.disableHeight) disable.setDisableHeight(true)
		}
		loader.copies = val
	}
	const updateWidth = (ev) => {
		if(!ev.target.value){
			setWidth("")
			setWidthProportion("")
			if(locked){
				setHeight("")
				setHeightProportion("")
			}
			return
		}
		let val = parseFloat(ev.target.value)
		setWidth(withDecimals(val))
		let proportion = val/(originalWidth * ms.pxToCm)*100
		setWidthProportion(proportion)
		loader.widthProportion = proportion
		loader.width = val * ms.cmToPx
		if(locked){
			setHeightProportion(proportion)
			loader.heightProportion = proportion
			loader.height = originalHeight * proportion / 100
			setHeight(withDecimals((originalHeight * ms.pxToCm)*proportion/100))
		}
	}
	const updateHeight = (ev) => {
		if(!ev.target.value){
			setHeight("")
			setHeightProportion("")
			if(locked){
				setWidth("")
				setWidthProportion("")
			}
			return
		}
		let val = parseFloat(ev.target.value)
		setHeight(withDecimals(val))
		let proportion = val/(originalHeight * ms.pxToCm)*100
		setHeightProportion(proportion)
		loader.heightProportion = proportion
		loader.height = val * ms.cmToPx
		if(locked){
			setWidthProportion(proportion)
			loader.widthProportion = proportion
			loader.width = originalWidth * proportion / 100
			setWidth(withDecimals((originalWidth * ms.pxToCm)*proportion/100))
		}
	}
	const updateWidthProportion = (ev) => {
		if(!ev.target.value){
			setWidth("")
			setWidthProportion("")
			if(locked){
				setHeight("")
				setHeightProportion("")
			}
			return
		}
		let val = parseFloat(ev.target.value)
		setWidthProportion(val)
		loader.widthProportion = val
		loader.width = originalWidth * val / 100
		setWidth(val/100*(originalWidth * ms.pxToCm))
		if(locked){
			setHeightProportion(val)
			loader.heightProportion = val
			loader.height = originalHeight * val / 100
			setHeight(val/100*(originalHeight * ms.pxToCm))
		}
	}
	const updateHeightProportion = (ev) => {
		if(!ev.target.value){
			setHeight("")
			setHeightProportion("")
			if(locked){
				setWidth("")
				setWidthProportion("")
			}
			return
		}
		let val = parseFloat(ev.target.value)
		setHeightProportion(val)
		loader.heightProportion = val
		loader.height = originalHeight * val / 100
		setHeight(val/100*(originalHeight * ms.pxToCm))
		if(locked){
			setWidthProportion(val)
			loader.widthProportion = val
			loader.width = originalWidth * val / 100
			setWidth(val/100*(originalWidth * ms.pxToCm))
		}
	}
	function doApiTask(type){
		apiSetters[type](1)
		setLoading(true)
		setErr()
		let body
		if(data.type === "file"){
			body = new FormData()
			body.append("img",loader.file,name)
		}else{
			body = {url: loader.url}
		}
		let req = {tries: 0}, t
		if(!auth) t = `${tid};${sid}`
		//if(type === "vect") return requestApiTask(type, body, req, false)
		requestApiTask(type, body, req, t)
	}
	function verifyApiTask(type, req, task_id, regID){
		http.post({url:`rectpack/verify_${type}`,body:{task_id, regID}}).then(res=>{
			if(res.status === 200){
				let url = res.data.url
				if(!url){
					let state = res.data.state
					if(state === 0) setStatus("En cola")
					else if(state === 2) setStatus("Preparando")
					else if(state === 3) setStatus("En espera")
					else setStatus("Procesando")
					setTimeout(()=>{
						verifyApiTask(type, req, task_id, regID)
					},2000)
					return
				}
				data.setProgress(0)
				let newLoader = new ImageLoader(url,data.setProgress,onLoaded)
				newLoader.copies = loader.copies
				data.loader = newLoader
				setTimeout(()=>{
					setLoader(newLoader)
					setLoading(false)
					setStatus()
					apiSetters[type](2)
					setTimeout(()=>{apiSetters[type](0)},60000)
				},200)
			}else{
				if(res.status !== 400){
					setLoading(false)
					setTimeout(()=>{apiSetters[type](0)},3000)
					if(res.data.message) return setErr(res.data.message)
					else if(res.data.msg) return setErr(res.data.msg)
					else if(res.status === 500) return setErr("Error del servidor")
					return setErr(res.data)
				}
				let state = res.data.state
				if(state === -2) setErr("Falló al guardar los resultados")
				else if(state === -3) setErr("La URL no es válida")
				else if(state === -5) setErr("Se excedió el límte de tamaño (15MB)")
				else if(state === -7) setErr("Imágen inválida/corrupta")
				else if(state === -8) setErr("Se excedió el tiempo límite (30 seg)")
				else setErr("Falló al procesar la solicitud")
				setTimeout(()=>{apiSetters[type](0)},30000)
				return setLoading(false)
			}
		})
	}
	function requestApiTask(type,body,req,t){
		http.post({url:`rectpack/mod_${type}`,body,auth,headers: t ? {t} : undefined}).then(res=>{
			if(res.status === 200){
				req.tries = 0
				verifyApiTask(type, req, res.data.task_id, res.data.regID)
				/*else{
					data.setProgress(0)
					console.log(res)
					let newLoader = new ImageLoader(res.data,data.setProgress,onLoaded)
					newLoader.copies = loader.copies
					data.loader = newLoader
					setTimeout(()=>{
						setLoader(newLoader)
						setLoading(false)
						setStatus()
						apiSetters[type](2)
						setTimeout(()=>{apiSetters[type](0)},60000)
					},200)
				}*/
			}else{
				if(req.tries < 5){
					req.tries++
					setTimeout(()=>{
						apiSetters[type](req)//nobg(req)
					},500)
				}else{
					console.error(res.data)
					setLoading(false)
					return setErr("Failed too many tries")
				}
			}
		})
	}
	const spinner = <CircularProgress size="1.5rem" sx={{mx:"auto",my:"auto",position:"absolute"}}/>
	function getApiButton(name, taskId, isEnabled, disabledCond){
		if(taskId === "nobg") return <Button variant='contained' disabled={isEnabled > 0 || loading || disabledCond} size='small' sx={{mx:"0.5rem",position:"relative"}} fullWidth onClick={()=>{if(!isEnabled) onToggleBgRemover(loader)}}>{(loading && (isEnabled === 1)) && spinner}{name}</Button>
		return <Button variant='contained' disabled={isEnabled > 0 || loading || disabledCond} size='small' sx={{mx:"0.5rem",position:"relative"}} fullWidth
		onClick={()=>{if(!isEnabled) doApiTask(taskId)}}>{(loading && (isEnabled === 1)) && spinner}{name}</Button>
	}
	function renderApiOptions(){
		let disabled = false, disabledMsg = "Debe esperar 1 minuto para volver a usar"
		if(originalWidth > 4096 || originalHeight > 4096){
			disabled = true
			disabledMsg = "Las dimensiones exceden el límite (4096x4096)"
		}
		if(size > 15728640){
			disabled = true
			disabledMsg = "El tamaño excede el límite (15MB)"
		}
		let nobgButton = getApiButton("Remover fondo","nobg",nobg,disabled)
		let enhaButton = getApiButton("Mejorar calidad","enha",enha,disabled)
		/*let nobgButton = <Button variant='contained' disabled={nobg > 0 || loading || disabled} size='small' sx={{mx:"0.5rem", position:"relative"}} fullWidth onClick={()=>{
			if(!nobg) doApiTask("nobg")
		}}>{(loading && (nobg === 1)) && spinner}Remover fondo</Button>
		let enhaButton = <Button variant='contained' disabled={enha > 0 || loading || disabled} size='small' sx={{mx:"0.5rem", position:"relative"}} fullWidth onClick={()=>{
			if(!enha) doApiTask("enha")
		}}>{(loading && (enha === 1)) && spinner}Mejorar calidad</Button>*/
		//let vectButton = <Button variant='contained'
		//console.log({nobg})
		return <Box sx={{display:"flex", flexDirection:"column", justifyContent: "center",my:"0.5rem"}}>
			<Box sx={{display:"flex", "> span":{width:"100%", display:"flex"}}}>
				{(nobg === 2) ? <Tooltip title={disabledMsg}><span>{nobgButton}</span></Tooltip> : nobgButton}
				{(enha === 2) ? <Tooltip title={disabledMsg}><span>{enhaButton}</span></Tooltip> : enhaButton}
			</Box>
			{disabled && <Typography color="warning.main">No se puede modificar la imagen.<br/>{disabledMsg}</Typography>}
			{err && <Typography color="error">{err}</Typography>}
			{status && <Typography>{status}</Typography>}
		</Box>
	}
	const defaultTransition = "0.2s ease-in-out"
	const copiesInputSx = (theme)=>({pointerEvents: showCopies ? "initial" : "none", opacity: showCopies ? 1 : 0,transition: defaultTransition, position:"absolute",width: showCopies ? "5rem" : 0,height:"100%",left:"50%","& .MuiInputBase-root":{transition: defaultTransition, width:showCopies ? "5rem" : 0,height:"100%",pl:"0.5rem",borderRadius:0,p:0, ":hover fieldset":{borderColor: theme.palette.primary.main}},"& fieldset":{p:0}, "& input":{px:2.5}})
	return <Item sx={{position:"relative",boxShadow:2,border:"1px solid #dfdfdf",borderRadius:2,borderTopLeftRadius:"1.2rem",borderTopRightRadius:"1.2rem", overflow:"hidden"}}>
		<Box sx={{position:"absolute",top:2,left:2, display:"flex",alignItems:"center"}}>
			<Tooltip title={locked ? "Desbloquear proporción" : "Bloquear proporción"} placement="top" arrow>
				<IconButton disabled={loading} sx={{mr:isMobile ? undefined : 0.5,mx: isMobile ? "0.75rem":undefined}} size="small" color="primary" onClick={()=>{setLocked(!locked)}}>
					{isMobile && <Typography fontSize="small" fontWeight={600} sx={{position:"absolute",bottom:"-1.25rem",color:"rgba(0,0,0,0.6)"}}>{locked ? "Desbloq." : "Bloquear"}</Typography>}
					{locked ? <LockIcon/> : <LockOpenIcon/>}
				</IconButton>
			</Tooltip>
			<Box sx={{position:"relative",width:"max-content",height:"max-content",display:"inline-block",mx: isMobile ? "0.75rem":undefined}}>
				<Tooltip title="Copias" placement="top" arrow>
					<IconButton disabled={loading || disable?.disableCopies} size="small" color="primary" sx={{zIndex:4,position:"relative"}} onClick={toggleCopies}>
						{isMobile && <Typography fontSize="small" fontWeight={600} sx={{position:"absolute",bottom:"-1.25rem",color:"rgba(0,0,0,0.6)"}}>Copias</Typography>}
						<ContentCopyIcon/>
					</IconButton>
				</Tooltip>
				<Box sx={{width:"100%",height:"100%",position:"absolute",top:0,left:0,bgcolor:"white",zIndex:3,borderRadius:"50%"}}/>
				<Box sx={{transition: defaultTransition, zIndex:2, position:"absolute", right: showCopies ? "-5rem" : 0, top:0, backgroundColor:"#fff", borderRadius:"50%", opacity: showCopies ? 1 : 0}}>
					<IconButton disabled={loading} size="small" color="primary" onClick={toggleCopies}>
						<CheckIcon/>
					</IconButton>
				</Box>
				<Input type="number" disabled={disable?.disableCopies} value={isNaN(copies) ? "" : copies} onKeyDown={(ev)=>{if(ev.key === "Enter") toggleCopies()}} onChange={updateCopies} size="small" sx={copiesInputSx}/>
			</Box>
			<Tooltip title="Recortar" placement="top" arrow>
				<IconButton disabled={loading} onClick={()=>{onToggleCrop(loader)}} size="small" color="primary" sx={{transition: defaultTransition, marginLeft: showCopies ? (isMobile ? "6rem" : "5.25rem") : (isMobile ? "0.75rem" : 0.5)}}>
					{isMobile && <Typography fontSize="small" fontWeight={600} sx={{position:"absolute",bottom:"-1.25rem",color:"rgba(0,0,0,0.6)"}}>Recortar</Typography>}
					<CropIcon/>
				</IconButton>
			</Tooltip>
			<Typography fontSize={24} fontWeight={700} color={loading ? "rgba(0,0,0,0.25)" : "primary"} sx={{
				transition: defaultTransition, ml: 1, userSelect: "none", pointerEvents: "none", cursor: "none",
				opacity: (!showCopies && copies > 1) ? 1 : 0}}>{isNaN(copies) ? "" : copies}</Typography>
		</Box>
		<Box sx={{position:"absolute",top:2,right:2}}>
			<Tooltip title="Borrar" placement="top" arrow>
				<IconButton disabled={loading} size="small" color="primary" sx={{mr: isMobile ? "0.75rem" : undefined}} onClick={()=>onRemove(key)}>
				{isMobile && <Typography fontSize="small" fontWeight={600} sx={{position:"absolute",bottom:"-1.25rem",color:"rgba(0,0,0,0.6)"}}>Borrar</Typography>}
					<DeleteIcon/>
				</IconButton>
			</Tooltip>
		</Box>
		<Divider variant="fullWidth" sx={{mt: isMobile ? "3rem" : "2rem"}}/>
			{renderApiOptions()}
		<Divider/>
		<Typography fontSize="large" fontWeight={500} noWrap>{name}</Typography>
		<Divider/>
		<Box sx={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
			<Typography fontSize="small" fontWeight={500}>Modificado: <Typography variant="span" fontWeight={600} color="primary">{lastModified}</Typography></Typography>
			<Typography fontSize="small" fontWeight={500}>Tamaño: <Typography variant="span" fontWeight={600} color="primary">{size ? minifySize(size) : ""}</Typography></Typography>
		</Box>
		<Box sx={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
			<Typography fontSize="small" fontWeight={500}>Dimensiones: <Typography variant="span" fontWeight={600} color="primary">{originalWidth && originalWidth+"x"+originalHeight}</Typography></Typography>
		</Box>
		<Divider/>
		<Box sx={inputSx}>
			<InputNumber disabled={!isLoaded()} label="Ancho" tag="cm" value={width} onChange={updateWidth}/>
			{locked ? <LinkIcon sx={{mx:1}}/> : <LinkOffIcon sx={{mx:1}}/>}
			<InputNumber disabled={!isLoaded()} label="Alto" tag="cm" value={height} onChange={updateHeight}/>
		</Box>
		<Box sx={{...inputSx,display:"none"}}>
			<InputNumber disabled={!isLoaded()} label="Proporción Ancho" tag="%" value={widthProportion} onChange={updateWidthProportion}/>
			{locked ? <LinkIcon sx={{mx:1}}/> : <LinkOffIcon sx={{mx:1}}/>}
			<InputNumber disabled={!isLoaded()} label="Proporción Alto" tag="%" value={heightProportion} onChange={updateHeightProportion}/>
		</Box>
		<Divider/>
		<Box sx={{
			display:"flex",alignItems:"center",justifyContent:"center",mt:1,"> img":{
				minHeight:"1%",maxHeight:"16rem",maxWidth:"100%",
				backgroundColor: "#eee", backgroundSize:"20px 20px", backgroundPosition:"0 0, 10px 10px",
				backgroundImage: "linear-gradient(45deg, rgba(0,0,0,0.2) 25%, transparent 25%, transparent 75%, rgba(0,0,0,0.2) 75%, rgba(0,0,0,0.2)), linear-gradient(45deg, rgba(0,0,0,0.2) 25%, transparent 25%, transparent 75%, rgba(0,0,0,0.2) 75%, rgba(0,0,0,0.2))",
			}}} minWidth="1rem" maxWidth="100%" height="16rem"><ImageComponent data={data} url={url} file={file} loader={loader} setLoader={setLoader} onLoaded={onLoaded}/></Box>
		{/*progress < 100 ?
		<LabeledCircularProgress value={progress}/> :
<Box sx={{"> img":{minHeight:"1%",maxHeight:"16rem"}}} minWidth="1rem" maxWidth="100%" minHeight="1rem" maxHeight="16rem"><img width="auto" src={url} alt=""/></Box>*/}
	</Item>
}

export default ImageItem;