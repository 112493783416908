const defaultAprops = {
	number: {
		'& input[type=number]': {
			'MozAppearance': 'textfield'
		},
		'& input[type=number]::-webkit-outer-spin-button': {
			'WebkitAppearance': 'none',
			margin: 0
		},
		'& input[type=number]::-webkit-inner-spin-button': {
			'WebkitAppearance': 'none',
			margin: 0
		}
	}
}
export default defaultAprops