import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useContext, useState } from "react"
import http from "../../libs/http"
import Typography from "@mui/material/Typography"
import { useNavigate, useOutletContext } from "react-router-dom"
//import IconButton from "@mui/material/IconButton"
import LaunchIcon from "@mui/icons-material/Launch"
import ButtonGroup from "@mui/material/ButtonGroup"
import { Context } from "../../contexts/general"

function Create(){
	const {setSnack} = useOutletContext()
	const navigate = useNavigate()
	const [data,setData] = useState()
	const [loading,setLoading] = useState(false)
	const {auth} = useContext(Context)
	const requestTempCanvas = () => {
		setLoading(true)
		http.post({url:"rectpack/create_temp",auth}).then(res=>{
			if(res.status === 200){
				setData(res.data.doc)
			}
			setLoading(false)
		})
	}
	function getURL(){
		return `${window.location.protocol}//${window.location.host}/rectpack/create_temp?tid=${data._id}&sid=${data.sid}`
	}
	return <Box sx={{height: "100%",display:"flex",flexDirection:"column",width:"fit-content",mx:"auto",justifyContent:"center"}}>
		{!data && !loading && <Button variant="contained" onClick={requestTempCanvas}>Crear Enlace</Button>}
		{loading && <CircularProgress/>}
		{data && <>
			<Typography>Datos del enlace</Typography>
			<ButtonGroup>
				<Button variant="contained" onClick={()=>{
					setSnack("Enlace copiado","info",{autohide:6000})
					navigator.clipboard.writeText(getURL())
				}}><ContentCopyIcon/></Button>
				<Button variant="text" disabled sx={{color:"rgba(0,0,0,0.54) !important", boxShadow:3, bgcolor:"white", ":hover":"white", textTransform:"none",
				/*boxShadow:2, borderStyle:"solid", borderLeft:0, borderRight:0, borderTopWidth:"1px", borderBottomWidth:"1px", borderColor: "rgba(0,0,0,0.54)"*/}}>
					{getURL()}
				</Button>
				<Button variant="contained" onClick={()=>{
					navigate(`../create_temp?tid=${data._id}&sid=${data.sid}`)
				}}><LaunchIcon/></Button>
			</ButtonGroup>
		</>}
	</Box>
}
export default Create