const expr = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)

/**
 * 
 * @param {String} str 
 * @returns {Boolean}
 */
function validatePassword(str){
	return expr.test(str)
}
export default validatePassword