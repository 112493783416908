import { useContext, useState } from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import IconButton from "@mui/material/IconButton"
import DownloadIcon from "@mui/icons-material/Download"
import RefreshIcon from '@mui/icons-material/Refresh'
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate"
import VisuallyHiddenInput from "../basicComps/visuallyHiddenInput"
import http from "../../libs/http"
import { Context } from "../../contexts/general"

function Vectorizer(){
	const [loading,setLoading] = useState(false)
	const [file,setFile] = useState()
	const [err,setErr] = useState()
	const {auth} = useContext(Context)
	const submitSVG = (ev) => {
		let files = ev.target.files
		let file = files[0], body = new FormData()
		console.log({file})
		body.append("img",file,file.name)
		setLoading(true)
		setErr()
		http.post({url:"rectpack/mod_vect",body,auth}).then(res=>{
			//let file = //window.URL.createObjectURL(res.data)
			if(res.status !== 200){
				setErr(res.data.msg)
				return setLoading(false)
			}
			setFile({url: window.URL.createObjectURL(res.data), name: file.name.substring(0,file.name.lastIndexOf(".")) + ".svg"})
			setLoading(false)
			//window.location.assign(file)
		})
	}
	const spinner = <CircularProgress size="3rem" sx={{mx:"auto",my:"auto"}}/>
	return <Box sx={{display:"flex",height:"100%",position:"relative"}}><Box sx={{display:"flex",mx:"auto",my:"auto",justifyContent:"center",width:"fit-content",flexDirection:"column", "& img":{width:"50vw"}}}>
		{(!loading && !file) && <>
		<IconButton component="label" sx={{mx:"auto", width:"12rem",height:"12rem", "& svg":{width:"8rem",height:"8rem"}}}>
			<VisuallyHiddenInput type="file" accept="image/png,image/jpeg,image/bmp,image/tiff" multiple={false} onInput={submitSVG}/>
			<AddPhotoAlternateIcon/>
		</IconButton>
		<Typography sx={{textDecoration:"none",pointerEvents:"none",userSelect:"none"}} fontSize="2rem" color="primary" mt="1rem" fontWeight={600}>Vectorizar imágen</Typography></>}
		{(!loading && err) && <Typography sx={{textDecoration:"none",pointerEvents:"none",userSelect:"none"}} color="error" mt="1rem">Error<br/>{err}</Typography>}
		{loading && spinner}
		{file && <>
			<IconButton sx={{position:"absolute",top:"0.5rem",left:"1rem"}} size="large" onClick={()=>{setFile();setErr()}}>
				<RefreshIcon/>
			</IconButton>
			<img src={file.url} alt=""/>
			<Button sx={{mt:"1rem", mx:"auto", width:"fit-content"}} variant="contained" startIcon={<DownloadIcon/>} href={file.url} download={file.name}>Descargar</Button>
		</>}
	</Box></Box>
}

export default Vectorizer