import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Box from "@mui/material/Box"
import DownloadIcon from "@mui/icons-material/Download"
import ms from "../constants/measurement"
import { Button } from "@mui/material"
import http from "../../libs/http"
import TableImage from "../basicComps/tableImage"

function cmsCb(val){
	return (val * ms.pxToCm).toFixed(2) + " cm"
}

function noneOnNull(val,tag){
	if(val) return val
	return <Typography component="i" sx={{opacity:0.54, userSelect:"none"}}>Sin {tag}</Typography>
}

function stateCb(val){
	if(val === 0) return <Typography color="warning.main" fontWeight={500}>En proceso</Typography>
	return <Typography color="success.main" fontWeight={500}>Finalizado</Typography>
}
const dataStructure = [
	["_id","ID"],
	["state","Estado",stateCb],
	["name","Nombre",noneOnNull],
	["desc","Descripción",noneOnNull],
	["w","Ancho",cmsCb],
	["h","Alto",cmsCb],
]

function getBody(state,data){
	const comps = []
	let f = dataStructure.length
	if(state === 0) f = 2
	for(let i = 0; i < f; i++){
		let val = data[dataStructure[i][0]], tag = dataStructure[i][1], fn = dataStructure[i][2]
		if(fn) val = fn(val,tag)
		comps.push(<TableRow key={`trow${i}`}>
			<TableCell component="th" variant="head">{tag}</TableCell>
			<TableCell>{val}</TableCell>
		</TableRow>)
	}
	return comps
}

/**
 * @param {{data:{id: Number, name: Number, desc: Number, user: Number, state: Number, mode: Number, w: Number, h: Number, qty: Number, algo: Number, create_date: Number, write_date: Number}}} param0 
 */
function View({data}){
	return <Box sx={{display:"flex",flexDirection:"column", width:"100%", alignItems:"center"}}><TableContainer component={Paper}>
		<Table>
			<colgroup>
				<col style={{width:'10%'}}></col>
			</colgroup>
			<TableBody>
			{getBody(data.state,data)}
			<TableRow>
				<TableCell component="th" variant="head">Vista previa</TableCell>
				<TableCell><TableImage src={`${http.url}rectpack/canvas_img/${data._id}?web=1`} size={[64,64]}/></TableCell>
			</TableRow>
		</TableBody></Table>
	</TableContainer>
	<Button sx={{mt:"1rem"}} variant="contained" startIcon={<DownloadIcon/>} href={`${http.url}rectpack/canvas_img/${data._id}`}>Descargar</Button>
	</Box>
}
export default View