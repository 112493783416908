import Box from "@mui/material/Box"
import { useContext, useEffect, useState } from "react"
import http from "../../libs/http"
import { Context } from "../../contexts/general"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import CircularProgress from "@mui/material/CircularProgress"
import Divider from "@mui/material/Divider"
import InputLabel from "@mui/material/InputLabel"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import SearchIcon from "@mui/icons-material/Search"
import { getDateStr } from "../scripts/dateFormat"

const methods = {
	mod_vect: "Vectorizar imágen",
	enha: "Mejorar calidad",
	nobg: "Remover fondo"
}

function Management(){
	/** @type {[Map|undefined,Function]} */
	const [data,setData] = useState()
	const [userData,setUserData] = useState()
	const [loading,setLoading] = useState(false)
	const [err,setErr] = useState()
	const [filter,setFilter] = useState("")
	const [dateRange,setDateRange] = useState(()=>{
		const from = new Date()
		from.setDate(from.getUTCDate())
		from.setHours(0,0,0,0)
		const to = new Date(from)
		to.setMonth(to.getMonth()+1)
		return [from,to]
	})
	const {auth} = useContext(Context)
	const updateFilter = (ev) => {
		setFilter(ev.target.value)
		setData()
	}
	/*useEffect(()=>{
		if(filter && !data && !loading && !err){
			setLoading(true)
			http.post({url:"rectpack/regsCount",auth,body:{r: filter}}).then(async res=>{
				if(res.status !== 200) return setErr(res.error)
				let docs = res.data
				if(docs.length > 0){
					let userData = new Map()
					
					setUserData(userData)
					for(let doc of docs){
						if(doc._id === null && !userData.has(null)){
							userData.set(null,{fname:"Usuario",lname:"público"})
						}else if(!userData.has(doc._id)){
							let usr = await http.get({url:`users/client/view/${doc._id}`,auth}).then()
							if(usr.status === 200){
								usr = usr.data
								userData.set(usr._id, usr)
							}
							console.log({userData})
						}
					}
				}
				setLoading(false)
				setData(res.data)
			})
			return
		}
	}, [data, loading, err, auth, userData, filter])*/
	//console.log(filter)
	function submit(){
		setLoading(true)
		setData()
		http.post({url:"rectpack/regsCount",auth,body:{r: filter, cAt: dateRange}}).then(res=>{
			const map = new Map()
			if(res.status === 200){
				//console.log(res.data)
				for(let doc of res.data){
					const _id = doc._id, key = `${_id.date};${_id.r}`
					const mapDoc = map.get(key)
					if(!mapDoc){
						const obj = {usrs: new Set(), total: doc.total}
						obj.usrs.add(_id.usr)
						map.set(key,obj)
					}else{
						mapDoc.usrs.add(_id.usr)
						mapDoc.total += doc.total
					}
				}
				setData(map)
				//console.log(map.entries().next())
				setLoading(false)
			}
		})
	}
	function renderData(){
		if(loading) return <TableRow><TableCell colSpan={filter ? 3 : 4} sx={{textAlign:"center"}}><CircularProgress/></TableCell></TableRow>
		if(!data) return
		if(data.size === 0) return <TableRow><TableCell colSpan={filter ? 3 : 4} sx={{textAlign:"center"}}><Typography component="i" sx={{opacity:0.54, userSelect:"none"}}>Sin resultados</Typography></TableCell></TableRow>
		const elems = []
		const it = data.entries()
		let doc = it.next()
		while(doc.value){
			const val = doc.value
			const split = val[0].split(";")
			const date = split[0], r = split[1]
			//, [date,r] = val[0].split(";")
			elems.push(<TableRow key={`tr${val[0]}`}>
				{!filter && <TableCell>{methods[r]}</TableCell>}
				<TableCell>{date}</TableCell>
				<TableCell>{val[1].usrs.size}</TableCell>
				<TableCell>{val[1].total}</TableCell>
			</TableRow>)
			doc = it.next()
		}
		return elems
	}
	function renderMethods(){
		const elems = []
		for(let method in methods){
			elems.push(<MenuItem key={`mi${method}`} value={method}>{methods[method]}</MenuItem>)
		}
		return elems
	}
	return <Box sx={{display:"flex", width:"100%", height:"100%", justifyContent:"center", alignItems:"center"}}>
		{err && <Typography>{err}</Typography>}
		<TableContainer component={Paper} sx={{mx:"0.5rem",td:{p:"0.5rem"},th:{p:"0.5rem"},maxHeight:"calc(100% - 4rem)"}}>
			<Table sx={{position:"relative"}}>
				<TableHead>
					<TableRow><TableCell colSpan={filter ? 3 : 4} sx={{p:"0 !important", position: "sticky", top: 0, backgroundColor: "white"}}>
						<Box sx={{display:"flex",alignItems:"center",flexDirection:"column", py:"0.5rem", px:"2rem"}}>
						<Typography fontWeight={600} sx={{mb:-1}}>Llamadas de API</Typography>
						<Box sx={{display:"flex",alignItems:"flex-end",width:"100%"}}>
						<Box>
						<FormControl variant="standard" sx={{minWidth:"12rem",mr:2}}>
							<InputLabel id="filter-label" htmlFor="filter">Método</InputLabel>
							<Select size="small" labelId="filter-label" inputProps={{id:"filter"}} value={filter} onChange={updateFilter} label="Método">
								{renderMethods()}
								{/*<MenuItem value="mod_vect">Vectorizar imágen</MenuItem>
								<MenuItem value="enha">Mejorar calidad</MenuItem>
								<MenuItem value="nobg">Remover fondo</MenuItem>*/}
							</Select>
						</FormControl>
						<FormControl variant="standard" sx={{minWidth:"7rem"}}>
							<InputLabel id="date-groupBy-label" htmlFor="date-groupBy">Agrupar en</InputLabel>
							<Select size="small" labelId="date-groupBy-label" defaultValue="d" inputProps={{id:"date-groupBy"}} label="Agrupar en">
								<MenuItem value="d">Días</MenuItem>
								{/*<MenuItem value="w">Semanas</MenuItem>
								<MenuItem value="m">Meses</MenuItem>*/}
							</Select>
						</FormControl>
						</Box>
						<Box sx={{"> div":{ml:2}, ml: "auto"}}>
						<TextField size="small" label="Desde" id="from" variant="standard" type="date" defaultValue={getDateStr(dateRange[0])} onChange={(ev)=>{
							/** @type {Date} */
							const date = new Date(ev.target.valueAsDate)
							date.setHours(24,0,0,0)
							//console.log(date)
							setDateRange(dates=>{
								dates[0] = date
								return [...dates]
							})
						}}/>
						<TextField size="small" label="Hasta" id="to" variant="standard" type="date" defaultValue={getDateStr(dateRange[1])} onChange={(ev)=>{
							/** @type {Date} */
							const date = ev.target.valueAsDate
							date.setHours(24,0,0,0)
							//console.log(date)
							setDateRange(dates=>{
								dates[1] = date
								return [...dates]
							})
						}}/>
						</Box>
						<IconButton sx={{ml: 1, mr: -2}} onClick={()=>{
							console.log(document.getElementById("from").valueAsDate)
							submit()
						}}><SearchIcon height="100%"/></IconButton>
						</Box></Box>
						<Divider/>
					</TableCell></TableRow>
				</TableHead>
				<TableHead>
					<TableRow>
						{/*<TableCell>Usuario</TableCell>
						<TableCell>Nombre</TableCell>
						<TableCell>Cantidad</TableCell>*/}
						{!filter && <TableCell>Método</TableCell>}
						<TableCell>Fecha</TableCell>
						<TableCell>Usuarios</TableCell>
						<TableCell>Solicitudes</TableCell>
						
					</TableRow>
				</TableHead>
				<TableBody>
				{renderData()/*!loading && data && data.length > 0 ? data.map((val,idx)=>{
						let usr = userData.get(val._id)
						//console.log(usr)
						if(!usr) return <TableRow key={`regCount${idx}`}><TableCell colSpan={3} sx={{textAlign:"center"}}></TableCell></TableRow>
						return <TableRow key={`regCount${idx}`}>
							<TableCell>{usr.eml ? usr.eml : usr.usr}</TableCell>
							<TableCell>{usr.fname + " " + usr.lname}</TableCell>
							<TableCell>{val.total}</TableCell>
						</TableRow>
					}) : data?.length === 0 && 
				*/}
				</TableBody>
			</Table>
		</TableContainer>
	</Box>
}

export default Management