import FormControl from "@mui/material/FormControl"
import OutlinedInput from "@mui/material/OutlinedInput"
import Typography from "@mui/material/Typography"
import InputAdornment from "@mui/material/InputAdornment"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import defaultAprops from "../../constants/hideInputArrows"

function CanvasInput({id,val,label,err,sx,end,type="text",options,disabled,onInput}){
	let aSx = {}
	if(type === "number") aSx = defaultAprops.number
	//console.log({disabled})
	return <FormControl size="small" sx={{...sx,...aSx}}>
		<InputLabel htmlFor={id} error={err !== undefined}>{label}</InputLabel>
		{options ? <Select value={val} onChange={onInput} id={id} label={label} sx={{textAlign:"left"}}>
			{options.map((val,idx)=><MenuItem key={`canvasWOpt${idx}`} value={val.val}>{val.label}</MenuItem>)}
		</Select> : <OutlinedInput disabled={disabled} error={err !== undefined}
		value={val} onInput={onInput} id={id} type={type} label={label}
		endAdornment={<InputAdornment position="end">{end}</InputAdornment>}/>}
		<Typography
			sx={{userSelect:"none",pointerEvents:"none",position:"absolute",bottom:"-1rem",left:"1rem",opacity: err !== undefined ? 1 : 0}}
			component="b" align="left" fontSize="small" color="error">{err}</Typography>
	</FormControl>
}
export default CanvasInput