import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress'
import { useContext, useEffect, useState } from "react";
import { Context } from "../contexts/general";
import http from "../libs/http";
import { useNavigate, redirect } from "react-router-dom";
import data from "../.data/server";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import CampaingIcon from "@mui/icons-material/Campaign"
import CloseIcon from '@mui/icons-material/Close';
import {getCookie, setCookie} from "./scripts/cookie";
import { Divider, IconButton } from "@mui/material";

const newsKey = "news0"

function SignIn(){
	const [user,setUser] = useState("")
	const [password,setPassword] = useState("")
	const [loading,setLoading] = useState(false)
	//const [verified,setVerified] = useState(false)
	const [err,setErr] = useState()
	const [recover,setRecover] = useState()
	const {setAuth,setUserInfo,setRedirect} = useContext(Context)
	const [hideCampaign,setHideCampaign] = useState(()=>{
		return getCookie("news0")
	})
	const navigate = useNavigate()
	const signin = () => {
		setLoading(true)
		setErr()
		/*http.post({url:"web/session/authenticate",body:{
			jsonrpc:"2.0",
			params:{
				db: data.db,
				login:user,
				password
			}
		}}).then(res=>{*/
		let body = {pw: password}
		if(user.includes("@")) body.eml = user
		else body.usr = user
		http.post({url:"users/user/signin",body}).then(res=>{
			console.log(res)
			if(res.data){
				if(res.data.error){
					setLoading(false)
					return setErr(res.data.error.message + ": " + res.data.error.data.message)
				}
				if(res.status !== 200){
					setLoading(false)
					if(res.status === 404 || res.status === 401) return setErr("Los datos proporcionados no son válidos")
					if(res.status === 403){
						const msg = res.data?.msg
						if(msg === "User unconfirmed") return setErr("Su usuario no está confirmado, por favor revise su correo para finalizar el proceso de verificación")
						if(msg === "User disabled") return setErr("Su usuario se encuentra deshabilitado. Por favor contacte al soporte técnico")
						if(msg === "User requires administrative confirmation") return setErr("Su usuario se encuentra en proceso de aprobación. Por favor inténtelo más tarde")
						return setErr("Su usuario presenta inconvenientes, por favor contacte al soporte técnico")
					}
					if(res.status === 400) return setErr("Los datos proporcionados no son válidos")
					return setErr(`${res.status} ${res.statusText}`)
				}
				let data = res.data.data
				setAuth(data.tkn)
				delete data.tkn
				delete data.skt_tkn
				setUserInfo(data)
				return navigate("/rectpack/home",{replace:true,state:{from:"signin",msg:"ok"}})
			}
			setLoading(false)
		})
	}
	const restore = () => {
		setLoading(true)
		http.post({url:"users/user/request_pw_restore",body:{eml: user}}).then(res=>{
			setLoading(false)
			if(res.status === 200){
				return setRecover(2)
			}
			setErr("Ocurrió un error en la solicitud")
		})
	}
	/*useEffect(()=>{
		if(loading && !verified){
			http.post({url:"rectpack/verify"}).then(res=>{
				console.log(res)
				if(res.data === '1'){
					setVerified(true)
					setAuth(true)
				}else{
					window.location.replace("/web/login?redirect=%2Frectpack%2Fcreate%3F")
				}
				setLoading(false)
			}).catch(err=>{
				console.log(err)
				setLoading(false)
			})
		}
		if(verified){
			return navigate("/rectpack/home",{replace:true,state:{from:"signin",msg:"ok"}})
		}
		return
	})*/
	const bsx = {transition: "0.2s linear",height:"100%",my:"auto",boxSizing:"border-box",display:"flex",flexDirection:"column",justifyContent:"center",p:2, backgroundColor:"rgba(0,0,0,0.1)"}
	if(recover) return <Box sx={bsx}>
		{recover === 1 && <Box component={Paper} sx={{display:"flex",flexDirection:"column",p:2}}>
			<Typography sx={{color:"rgba(0,0,0,0.6)",fontSize:"1.5rem",pointerEvents:"none",userSelect:"none"}} component="b">Restaurar contraseña</Typography>
			<TextField disabled={loading}
				id="user" label="Correo" type="email" variant="standard"
				value={user} onInput={(ev)=>{setUser(ev.target.value)}}/>
			<Button disabled={loading} onClick={restore} sx={{mt:2,position:"relative", width:"max-content", mx:"auto"}} variant="contained">
				Solicitar
				{loading && <CircularProgress sx={{mx:"auto",position:"absolute"}}/>}
		</Button>
		{err && <Typography mt={1} color="error">{err}</Typography>}
		</Box>}
		{recover === 2 && <><Typography>Solicitud enviada</Typography><Typography>Revisa tu correo para verificar el proceso de restauración</Typography><Button variant="contained" sx={{width:"max-content",mx:"auto", mt:2}} onClick={()=>{setRecover();setUser();setPassword()}}>Regresar</Button></>}
	</Box>
	return <Box sx={bsx}>
		{!hideCampaign && <Box component={Paper} sx={{display:"flex",flexDirection:"column",pb:2,pt:1, mb:2}}>
			<Box sx={{display:"flex", pl:2,pr:1, alignItems:"center",justifyContent:"space-between"}}><CampaingIcon/><Typography component="b">Atención</Typography><IconButton size="small" onClick={()=>{
				setCookie("news0",1)
				setHideCampaign("1")
			}}><CloseIcon/></IconButton></Box>
			<Divider/>
			<Box sx={{px:2,pt:1, "> *":{textAlign:"justify",textJustify:"inter-word"}}}>
				{/*<Typography>¡Únete a nuestra comunidad!</Typography>
				<br/>
				<Typography>Armalo Studio está en fase beta y estamos emocionados de que estés interesado en probar nuestra plataforma antes del lanzamiento oficial.</Typography>
				<br/>
				<Typography>¿Quieres obtener un enlace de prueba? realiza tu pre-registro ahora mismo aquí: <Link href="/register">https://www.armalostudio.com/register</Link></Typography>
				<br/>*/}
				<Typography>🎉 ¡Estamos emocionados de tenerte aquí en esta nueva etapa! 🎉</Typography><br/>
				<Typography>Ahora puedes formar parte de esta siguiente fase y ser de los primeros en probar Ármalo Studio, la herramienta que transformará tu flujo de trabajo: mejora imágenes, borra fondos, vectoriza y empaqueta en lienzos automáticamente.</Typography><br/>
				<Typography>¿Listo para experimentar todo esto?</Typography>
				<Typography>Regístrate ahora y disfruta de una prueba gratuita de 15 días.</Typography>
			</Box>
		</Box>}
		<Box component={Paper} sx={{display:"flex",flexDirection:"column",p:2}}>
		<Typography sx={{color:"rgba(0,0,0,0.6)",fontSize:"1.5rem",pointerEvents:"none",userSelect:"none"}} component="b">
			Ingresar
		</Typography>
		<TextField disabled={loading}
			id="user" label="Correo" type="email" variant="standard"
			value={user} onInput={(ev)=>{setUser(ev.target.value)}}/>
		<TextField disabled={loading}
			id="password" label="Contraseña" type="password" variant="standard"
			value={password} onInput={(ev)=>{setPassword(ev.target.value)}} onKeyUp={(ev)=>{if(ev.key === "Enter")signin()}}/>
		<Button disabled={loading} onClick={signin} sx={{mt:2,position:"relative", width:"max-content", mx:"auto"}} variant="contained">
			Iniciar Sesión
			{loading && <CircularProgress sx={{mx:"auto",position:"absolute"}}/>}
		</Button>
		{<Link href="/rectpack/signup" underline="none" sx={{mt:1}}>¿No tienes cuenta? Regístrate aquí</Link>}
		<Link underline="none" onClick={()=>{
			if(loading) return
			setRecover(1)
			setErr()
		}} sx={{mt:1,cursor:"pointer"}}>¿Olvidó su contraseña? Restaurela aquí</Link>
		{err && <Typography mt={1} color="error">{err}</Typography>}
		</Box>
	</Box>
}
export default SignIn;