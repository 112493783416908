const months = ["Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"]
/** @param {Date} date */
function localizeDate(date,separator="@"){
	if(!date) return
	if(typeof date === "string" || typeof date === "number") date = new Date(date)
	let day = date.getDate().toString().padStart(2,"0")
	let month = (months[date.getMonth()]).toString().padStart(2,"0")
	let year = date.getFullYear().toString()
	let hours = date.getHours().toString().padStart(2,"0")
	let minutes = date.getMinutes().toString().padStart(2,"0")
	let seconds = date.getSeconds().toString().padStart(2,"0")
	return `${day}-${month}-${year}${separator}${hours}:${minutes}:${seconds}`
}
export default localizeDate