import { forwardRef, useState } from "react"
import styled from "@mui/material/styles/styled"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"

const SlideButton = styled(Button)({
	position:"absolute",
	minWidth: "1rem",
	height: "1rem",
	padding: 0,
	borderRadius:"50%",
	border: "1px solid #fff",
	"& span":{display:"none"}
})

const ActiveCrop = forwardRef(function({zoom,coords,setCoords,onConfirm},ref){
	const [anchorCoords,setAnchorCoords] = useState({
		tl:[0,0],
		tr:[0,0],
		bl:[0,0],
		br:[0,0]
	})
	/** First and second position correspond to anchor adyacent coords.
	 * Third position correspond to custom style
	 */
	const anchorData = {
		tl:["tr","bl",{borderTopLeftRadius:0}],
		tr:["tl","br",{borderTopRightRadius:0}],
		bl:["br","tl",{borderBottomLeftRadius:0}],
		br:["bl","tr",{borderBottomRightRadius:0}]
	}
	const onMouseDown = function(key){
		return (ev) => {
			let elem = ev.target
			let x = elem.offsetLeft + elem.offsetHeight / 2
			let y = elem.offsetTop + elem.offsetWidth / 2
			setCoords({x, y, ix: x, iy: y, key})
		}
	}
	const onTocuhDown = function(key){
		return (ev) => {
			let elem = ev.target
		}
	}
	const onMouseUp = (ev) => {
		setCoords(anchorCoords)
	}
	const onMouseMove = (ev) => {
		if(!coords || !coords.key) return
		const coord = anchorCoords[coords.key]
		const adyCoordX = anchorCoords[anchorData[coords.key][0]]
		const adyCoordY = anchorCoords[anchorData[coords.key][1]]
		let mx = ev.movementX, my = ev.movementY
		coord[0] += mx
		coord[1] += my
		adyCoordX[1] += my
		adyCoordY[0] += mx
		setAnchorCoords({...anchorCoords})
	}
	/*const anchorOffset = "0rem"
	const _coordModifs = {
		tl: {top:`calc(${anchorOffset} + ${anchorCoords.tl[1]}px)`,left:`calc(${anchorOffset} + ${anchorCoords.tl[0]}px)`},
		tr: {top:`calc(${anchorOffset} + ${anchorCoords.tr[1]}px)`,right:`calc(${anchorOffset} - ${anchorCoords.tr[0]}px)`},
		bl: {bottom:`calc(${anchorOffset} - ${anchorCoords.bl[1]}px)`,left:`calc(${anchorOffset} + ${anchorCoords.bl[0]}px)`},
		br: {bottom:`calc(${anchorOffset} - ${anchorCoords.br[1]}px)`,right:`calc(${anchorOffset} - ${anchorCoords.br[0]}px)`}
	}*/
	const anchorPos = {
		tl: {top:0,left:0},
		tr: {top:0,right:0},
		bl: {bottom:0,left:0},
		br: {bottom:0,right:0}
	}
	function renderSliders(){
		//console.log("rendering sliders")
		const pos = ["tl","tr","bl","br"]
		
		return pos.map((val)=><SlideButton key={`${val}Slider`} variant="contained" sx={{...anchorPos[val], ...anchorData[val][2]}}
		onMouseDown={onMouseDown([val])} onTouchStart={(ev)=>{
			//console.log(ev.target)
			let elem = ev.target
			let x = elem.offsetLeft + elem.offsetHeight / 2
			let y = elem.offsetTop + elem.offsetWidth / 2
			setCoords({x, y, ix: x, iy: y, key: val})
			//console.log({x,y})
		}} />)
	}
	const cropStyle = {
		/*pt: `${anchorCoords.tl[1]}px`,
		pl: `${anchorCoords.tl[0]}px`,
		pb: `${anchorCoords.bl[1]}px`,
		pr: `${anchorCoords.bl[0]}px`,*/
		/*top: `${anchorCoords.tl[1]}px`,
		left: `${anchorCoords.tl[0]}px`,
		right: `${anchorCoords.br[0]*-1}px`,
		bottom: `${anchorCoords.br[1]*-1}px`,*/
		top:0,left:0,
		boxSizing:"border-box",
		pt: `${anchorCoords.tl[1]}px`,
		pl: `${anchorCoords.tl[0]}px`,
		pr: `${anchorCoords.br[0]*-1}px`,
		pb: `${anchorCoords.br[1]*-1}px`,
		width: "100%",//`calc(100% - (${anchorCoords.tl[0]}px + ${anchorCoords.tr[0]}px))`,
		height: "100%"//`calc(100% - (${anchorCoords.tl[1]}px + ${anchorCoords.bl[1]}px))`
	}
	const previousTouch = {pageX:-1,pageY:-1}
	return <Box ref={ref} sx={{position:"absolute", ...cropStyle}} onTouchMove={(ev)=>{
		let touch = ev.touches[0]
		if(previousTouch.pageX >= 0){
			if(!coords || !coords.key) return
			const coord = anchorCoords[coords.key]
			const adyCoordX = anchorCoords[anchorData[coords.key][0]]
			const adyCoordY = anchorCoords[anchorData[coords.key][1]]
			let movementX = touch.pageX - previousTouch.pageX
			let movementY = touch.pageY - previousTouch.pageY
			let mx = movementX, my = movementY
			coord[0] += mx
			coord[1] += my
			adyCoordX[1] += my
			adyCoordY[0] += mx
			setAnchorCoords({...anchorCoords})
			//console.log({movementX,movementY})
		}
		previousTouch.pageX = touch.pageX
		previousTouch.pageY = touch.pageY
	}} onMouseUp={onMouseUp} onMouseLeave={onMouseUp} onMouseMove={onMouseMove} onTouchEnd={(ev)=>{
			setCoords(anchorCoords)
			previousTouch.pageX = -1;previousTouch.pageY = -1}
		}>
		<Box sx={{position:"absolute",top:0,left:0,
			width:`calc(100% - ${anchorCoords.tr[0]*-1}px)`,
			height:`${anchorCoords.tl[1]}px`,
			bgcolor: "rgba(0,0,0,0.25)"
		}}/>
		<Box sx={{position:"absolute",top:0,right:0,
			width:`${anchorCoords.tr[0]*-1}px`,
			height:`calc(100% - ${anchorCoords.br[1]*-1}px)`,
			bgcolor: "rgba(0,0,0,0.25)"
		}}/>
		<Box sx={{position:"absolute",bottom:0,right:0,
			width:`calc(100% - ${anchorCoords.bl[0]}px)`,
			height:`${anchorCoords.br[1]*-1}px`,
			bgcolor: "rgba(0,0,0,0.25)"
		}}/>
		<Box sx={{position:"absolute",bottom:0,left:0,
			width:`${anchorCoords.bl[0]}px`,
			height:`calc(100% - ${anchorCoords.tl[1]}px)`,
			bgcolor: "rgba(0,0,0,0.25)"
		}}/>
		<Box sx={{
			position:"relative",width:"100%",height:"100%",
			":before":{
				content:'""',
				position: "absolute",
				width:"calc(100% + 2px)",height:"calc(100% + 2px)",
				border: "1px solid #fff",
				borderRadius: "3px",
				top:"-2px",left:"-2px"
				/*backgroundImage: "conic-gradient()"*/
			}
		}}>{renderSliders()}</Box>
	</Box>
})

export default ActiveCrop
