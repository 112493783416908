import { useState } from 'react'
import Paper from '@mui/material/Paper'
import Box from "@mui/material/Box"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import LockIcon from "@mui/icons-material/Lock"
import LockOpenIcon from '@mui/icons-material/LockOpen'
import CropIcon from "@mui/icons-material/Crop"
import DeleteIcon from "@mui/icons-material/Delete"
import LinkIcon from "@mui/icons-material/Link"
import LinkOffIcon from "@mui/icons-material/LinkOff"
import CheckIcon from "@mui/icons-material/Check"
import styled from "@mui/material/styles/styled"
import DividerMui from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import InputAdornment from "@mui/material/InputAdornment"
import Tooltip from '@mui/material/Tooltip'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { isMobile } from 'react-device-detect'
import ImageComponent from './image'
import ContainedIconButton from '../../basicComps/containedIconButton'
import ms from '../../constants/measurement'

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}))
const IconButton = ContainedIconButton
const Divider = styled(DividerMui)({
	width:"calc(100% + 1rem)",
	marginLeft:"-0.5rem"
})
const Input = styled(TextField)({
	"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
})
function minifySize(size){
	if(size >= 1048576){ // mB
		return `${(size/1048576).toPrecision(3)} MB`
	}
	if(size >= 1024){ // kB
		return `${(size/1024).toPrecision(3)} KB`
	}
	return `${size} B`
}
const inputSx = {my:1,display:"flex",alignItems:"center",justifyContent:"center"}
function InputNumber({label,tag,onChange,value,disabled}){
	return <Input sx={{width:"100%"}}
		disabled={disabled} type="number" size="small" label={label} onChange={onChange} value={value}
		InputProps={{endAdornment:<InputAdornment sx={{pointerEvents:"none",cursor:"none",userSelect:"none"}} position="end" component="span">{tag}</InputAdornment>}}/>
}
function withDecimals(value,decimals=2){
	let zeros = Math.pow(10,decimals)
	return Math.round((value + Number.EPSILON) * zeros) / zeros 
}
function ImageResult({data}){
	const {url,previousWidth, previousHeight, name} = data
	const [locked,setLocked] = useState(true)
	const [originalWidth,setOriginalWidth] = useState(data.width)
	const [originalHeight,setOriginalHeight] = useState(data.height)
	const [width,setWidth] = useState(data.width)
	const [height,setHeight] = useState(data.height)
	const [widthProportion,setWidthProportion] = useState("")
	const [heightProportion,setHeightProportion] = useState("")
	const [copies,setCopies] = useState(data.copies)
	const [showCopies,setShowCopies] = useState(false)
	const [loader,setLoader] = useState()
	function isLoaded(){
		if(!loader) return false
		if(loader && loader.state < 2) return false
		return true
	}
	const toggleCopies = () => {
		setShowCopies(!showCopies)
	}
	const onLoaded = (width,height) => {
		setOriginalWidth(width)
		setWidth(withDecimals(width * ms.pxToCm))
		setOriginalHeight(height)
		setHeight(withDecimals(height * ms.pxToCm))
		setWidthProportion(100)
		setHeightProportion(100)
		setCopies(1)
	}
	const updateCopies = (ev) => {
		let val = parseInt(ev.target.value)
		if(!isNaN(val) && val <= 0) val = 1
		else if(val > 9999) val = 9999
		setCopies(val)
		loader.copies = val
	}
	const updateNobg = () => {
		if(!loader.nobg) loader.nobg = 1
		else loader.nobg = 0
	}
	const updateEnha = () => {
		if(!loader.enha) loader.enha = 1
		else loader.enha = 0
	}
	const updateWidth = (ev) => {
		if(!ev.target.value){
			setWidth("")
			setWidthProportion("")
			if(locked){
				setHeight("")
				setHeightProportion("")
			}
			return
		}
		let val = parseFloat(ev.target.value)
		setWidth(withDecimals(val))
		let proportion = val/(originalWidth * ms.pxToCm)*100
		setWidthProportion(proportion)
		loader.widthProportion = proportion
		loader.width = val * ms.cmToPx
		if(locked){
			setHeightProportion(proportion)
			loader.heightProportion = proportion
			loader.height = originalHeight * proportion / 100
			setHeight(withDecimals((originalHeight * ms.pxToCm)*proportion/100))
		}
	}
	const updateHeight = (ev) => {
		if(!ev.target.value){
			setHeight("")
			setHeightProportion("")
			if(locked){
				setWidth("")
				setWidthProportion("")
			}
			return
		}
		let val = parseFloat(ev.target.value)
		setHeight(withDecimals(val))
		let proportion = val/(originalHeight * ms.pxToCm)*100
		setHeightProportion(proportion)
		loader.heightProportion = proportion
		loader.height = val * ms.cmToPx
		if(locked){
			setWidthProportion(proportion)
			loader.widthProportion = proportion
			loader.width = originalWidth * proportion / 100
			setWidth(withDecimals((originalWidth * ms.pxToCm)*proportion/100))
		}
	}
	const updateWidthProportion = (ev) => {
		if(!ev.target.value){
			setWidth("")
			setWidthProportion("")
			if(locked){
				setHeight("")
				setHeightProportion("")
			}
			return
		}
		let val = parseFloat(ev.target.value)
		setWidthProportion(val)
		loader.widthProportion = val
		loader.width = originalWidth * val / 100
		setWidth(val/100*(originalWidth * ms.pxToCm))
		if(locked){
			setHeightProportion(val)
			loader.heightProportion = val
			loader.height = originalHeight * val / 100
			setHeight(val/100*(originalHeight * ms.pxToCm))
		}
	}
	const updateHeightProportion = (ev) => {
		if(!ev.target.value){
			setHeight("")
			setHeightProportion("")
			if(locked){
				setWidth("")
				setWidthProportion("")
			}
			return
		}
		let val = parseFloat(ev.target.value)
		setHeightProportion(val)
		loader.heightProportion = val
		loader.height = originalHeight * val / 100
		setHeight(val/100*(originalHeight * ms.pxToCm))
		if(locked){
			setWidthProportion(val)
			loader.widthProportion = val
			loader.width = originalWidth * val / 100
			setWidth(val/100*(originalWidth * ms.pxToCm))
		}
	}
	return <Item sx={{position:"relative",boxShadow:2,border:"1px solid #dfdfdf",borderRadius:2,borderTopLeftRadius:"1.2rem",borderTopRightRadius:"1.2rem", overflow:"hidden"}}>
		<Box sx={{position:"absolute",top:2,left:2, display:"flex",alignItems:"center"}}>
			<Tooltip title={locked ? "Desbloquear proporción" : "Bloquear proporción"} placement="top" arrow>
				<IconButton sx={{mr:isMobile ? undefined : 0.5,mx: isMobile ? "0.75rem":undefined}} size="small" color="primary" onClick={()=>{setLocked(!locked)}}>
					{isMobile && <Typography fontSize="small" fontWeight={600} sx={{position:"absolute",bottom:"-1.25rem",color:"rgba(0,0,0,0.6)"}}>{locked ? "Desbloq." : "Bloquear"}</Typography>}
					{locked ? <LockIcon/> : <LockOpenIcon/>}
				</IconButton>
			</Tooltip>
		</Box>
		<Divider variant="fullWidth" sx={{mt: isMobile ? "3rem" : "2rem"}}/>
		<Typography fontSize="large" fontWeight={500} noWrap>{name}</Typography>
		<Divider/>
		<Box sx={inputSx}>
			<Box sx={{textAlign: "left", width: "100%", paddingLeft: "0.8rem"}}>
				<div>Ancho Previo</div>
				<b>{withDecimals(previousWidth * ms.pxToCm)} cm</b>
			</Box>
			<Box sx={{textAlign: "left", width: "100%", paddingLeft: "3.2rem"}}>
				<div>Alto Previo</div>
				<b>{withDecimals(previousHeight * ms.pxToCm)} cm</b>
			</Box>
		</Box>
		<Box sx={inputSx}>
			<InputNumber disabled={!isLoaded()} label="Ancho" tag="cm" value={width} onChange={updateWidth}/>
			{locked ? <LinkIcon sx={{mx:1}}/> : <LinkOffIcon sx={{mx:1}}/>}
			<InputNumber disabled={!isLoaded()} label="Alto" tag="cm" value={height} onChange={updateHeight}/>
		</Box>
		<Divider/>
		<Box sx={{display:"flex",alignItems:"center",justifyContent:"center",mt:1,"> img":{minHeight:"1%",maxHeight:"16rem",maxWidth:"100%"}}} minWidth="1rem" maxWidth="100%" height="16rem"><ImageComponent data={data} url={url} loader={loader} setLoader={setLoader} onLoaded={onLoaded}/></Box>
	</Item>
}

export default ImageResult;