/**
 * @param {Date} date 
 * @returns {String}
 */
function getDateStr(date,options){
	if(options){
		date = new Date(date)
		if(options.add){
			const val = options.add[0], type = options.add[1]
			if(type === "month") date.setMonth(date.getMonth()+val)
		}
	}
	return `${date.getFullYear()}-${date.getMonth().toString().padStart(2,"0")}-${date.getDate().toString().padStart(2,"0")}`
}

export {getDateStr}