import {useState,forwardRef, useEffect, useRef, useContext} from "react"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Unstable_Grid2"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import MuiToggleButton from "@mui/material/ToggleButton"
import styled from "@mui/material/styles/styled"
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate"
import ImageItem from "./imageItem"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
//import CheckIcon from "@mui/icons-material/Check"
import BlockIcon from "@mui/icons-material/Block"
import TextField from "@mui/material/TextField"
import CircularProgress from "@mui/material/CircularProgress"
import Snackbar from "@mui/material/Snackbar"
import MuiAlert from "@mui/material/Alert"
import Paper from "@mui/material/Paper"
import useMediaQuery from "@mui/material/useMediaQuery"
import ContainedIconButton from "../basicComps/containedIconButton"
import VisuallyHiddenInput from "../basicComps/visuallyHiddenInput"
import ms from "../constants/measurement"
import http from "../../libs/http"
import CanvasInput from "./components/canvasInput"
import UploadingImage from "./components/uploadingImage"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import Typography from "@mui/material/Typography"
// import Tooltip from "@mui/material/Tooltip"
import MenuItem from "@mui/material/MenuItem"
import {isMobile} from "react-device-detect"
import Crop from "./crop/index"
// eslint-disable-next-line no-unused-vars
import ImageLoader from "./components/imageLoader"
import {Context} from "../../contexts/general"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import defaultAprops from "../constants/hideInputArrows"
import ImageResult from "./components/imageResult"
import EditableCanvasInput from "./components/editableCanvasInput"
import TooltipCanvasInput from "./components/tooltipCanvasInput"
import BackgroundRemover from "./background"
import { getCookie } from "../scripts/cookie"

const Alert = forwardRef(function Alert(props, ref){
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props}/>
})
const ToggleButton = styled(MuiToggleButton)(({theme})=>({
	"&.Mui-selected, &.Mui-selected:hover":{
		backgroundColor: theme.palette.primary.main,
		color: "#fff"
	}
}))
const inputWidth = {xs: "65%", sm: "70%", md: "80%"}
const errLims = {
	val: ["El valor no puede ser",<BlockIcon fontSize="0.5rem" sx={{mt:"0.25rem"}}/>],
	lt: ["menor que","<",(val,lim)=>val < lim],
	lte: ["menor o igual que", "<=",(val,lim)=>val <= lim],
	gt: ["mayor que",">",(val,lim)=>val > lim],
	gte: ["mayor o igual que",">=",(val,lim)=>val >= lim],
	eq: ["igual que","==",(val,lim)=>val === lim]
}

class InputErrors{
	#errors = new Map()
	#onUpdate = null
	constructor(errors,onUpdate){
		for(let error in errors){
			this.#errors.set(error,errors[error])
		}
		this.#onUpdate = onUpdate
	} 
	onUpdate(key,obj){
		this.#onUpdate((errors)=>{
			errors[key] = obj
			return {...errors}
		})
	}
	get(key){
		return this.#errors.get(key)
	}
	msg(key,type=0){
		let obj = this.#errors.get(key)
		if(!obj || !obj.show) return
		let lim = obj.show
		if(lim === "req") return type === 0 ? "Campo obligatorio" : "*"
		lim = lim[0]
		let str = errLims[lim][type]
		if(type === 0) return `${errLims.val[0]} ${str} ${obj.lims[lim]}`
		return [<div key={`${key}ico`}>{errLims.val[1]}</div>,<div key={`${key}msg`}>{str}{obj.lims[lim]}</div>]
	}
	reset(key){
		let obj
		if(key){
			obj = this.get(key)
			if(!obj) return
			if(!obj.show) return
			obj.show = undefined
		}else return
		this.#onUpdate((errors)=>{
			errors[key] = obj.show
			return {...errors}
		})
	}
	validate(key,val){
		let obj = this.get(key)
		//console.log({key,val})
		if(!obj) throw new Error("Invalid field")
		if(!val){
			if(obj.req) this.onUpdate(key,"req")
			return val
		}
		let lims = obj.lims
		if(!lims){
			this.reset(key)
			return val
		}
		val = parseFloat(val)
		for(let lim in lims){
			if(errLims[lim][2](val,lims[lim])){
				obj.show = [lim,val]
				this.onUpdate(key,obj.show)
				//console.log("updating",key,obj)
				return val
			}
		}
		this.reset(key)
		return val
	}
}

function Editor({reset}){
	const navigate = useNavigate()
	const location = useLocation()
	const [mode,setMode] = useState("multi")
	const [showInputs,setShowInputs] = useState(true)
	const [canvasDoc,setCanvasDoc] = useState()
	const [canvasName,setCanvasName] = useState("")
	const [canvasDesc,setCanvasDesc] = useState("")
	const [userPhone,setUserPhone] = useState("")
	const [canvasWidth,setCanvasWidth] = useState("")
	const [canvasHeight,setCanvasHeight] = useState("")
	const [disableHeight,setDisableHeight] = useState(false)
	const [disableCopies,setDisableCopies] = useState(false)
	const [imagesMargin,setImagesMargin] = useState("")
	const [loading,setLoading] = useState(location.state?._id ? true : false)
	const [activeLoader,setActiveLoader] = useState()
	const [bgremoveAllMethods,setBgremoveAllMethods] = useState(()=>{return getCookie("all-bgremover-methods")})
	const [message,setMessage] = useState()
	const [status,setStatus] = useState({step:0})
	const [apiStatus,setApiStatus] = useState()
	const [showSnackbar,setShowSnackbar] = useState(false)
	const [showCrop,setShowCrop] = useState(false)
	const [showBgRemover,setShowBgRemover] = useState(false)
	const [zoom,setZoom] = useState(100)
	const [queryParams] = useSearchParams()
	const {auth} = useContext(Context)
	/** Input errors */
	const [errors,setErrors] = useState(()=>{
		const vals = {width: undefined/*"req"*/,height: undefined/*"req"*/,margin:undefined}
		if(!auth){
			vals.name = undefined/*"req"*/
			vals.phone = undefined/*"req"*/
			vals.desc = undefined
		}
		return vals
	})
	/** @type {[CanvasRenderingContext2D,Function]} */
	const [context,setContext] = useState()
	const match = useMediaQuery("(min-width:540px)")
	/** @type {[{map: Map<String,{file:File, loader: ImageLoader}>, updating: Boolean},Function]} */
	const [images,setImages] = useState({
		map: new Map(),
		updating: false
	})
	const canvasRef = useRef(null)
	const errorValidator = new InputErrors(
		{width:{show: errors.width, lims:{lte:0,gt:180},req:true},
		height:{show: errors.height, lims:{lte:0,gt:100},req:false},
		margin:{show: errors.margin, lims:{lt:0,gt:100}},
		name: {show: errors.name, req: true},
		desc: {show: errors.desc},
		phone: {show: errors.phone, req: true}
		},
		setErrors
	)
	useEffect(()=>{
		if(!context){
			const canvas = canvasRef.current
			if(!canvas) return
			return setContext(canvas.getContext("2d"))
		}
	},[context])
	useEffect(()=>{
		if(location.state?._id && loading && canvasDoc === undefined){
			let _id = location.state._id
			http.get({url:`rectpack/get_canvas/${_id}`,auth}).then(async res=>{
				if(res.status !== 200){
					setCanvasDoc(null)
					return onFinish("warning","No se pudo encontrar información del trabajo solicitado")
				}
				let doc = res.data
				doc.unfinished = []
				let imgs = (await http.get({url:`rectpack/list_items/${_id}`,auth}).then()).data
				console.log({imgs})
				for(let img of imgs){
					let key = `${img._id.n}-${img._id.cid}`
					if(img.state === 0){
						doc.unfinished.push({_id: key, name: img.name, qty: img.qty, w: img.w, h: img.h})
						continue
					}
					images.map.set(key,{
						url:`${http.url}rectpack/item_img/${img._id.n}-${img._id.cid}`,
						_id: img._id,
						name: img.name,
						w: img.w - doc.m * 2,
						h: img.h - doc.m * 2,
						qty: img.qty,
						loader:undefined
					})
				}
				setLoading(false)
				setCanvasDoc(doc)
				setMode(doc.mode === 0 ? "multi" : "single")
				if(doc.name) setCanvasName(doc.name)
				if(doc.desc) setCanvasDesc(doc.desc)
				setCanvasWidth((ms.pxToCm*doc.ow).toFixed(2))
				setImages({map: images.map, updating: false})
				if(doc.oh) setCanvasHeight((ms.pxToCm*doc.oh).toFixed(2))
				setImagesMargin(parseFloat((doc.m * ms.pxToCm / 10).toFixed(2)))
				if(doc.phn) setUserPhone(doc.phn)
			})
		}
	},[location, loading, auth, images, canvasDoc])
	const toggleCrop = (loader) => {
		setShowCrop(!showCrop)
		if(!showCrop){
			setActiveLoader(loader)
			if(!canvasRef.current) return
			canvasRef.current.width = loader.img.width
			canvasRef.current.height = loader.img.height
			context.drawImage(loader.img,0,0)
		}else setActiveLoader(null)
	}
	const toggleBgRemover = (loader) => {
		setShowBgRemover(!showBgRemover)
		if(!showBgRemover){
			setActiveLoader(loader)
			const allBgremoverMethods = getCookie("all-bgremover-methods")
			//if(!canvasRef.current) return
			//canvasRef.current.width = loader.img.width
			//canvasRef.current.height = loader.img.height
			//context.drawImage(loader.img,0,0)
		}else setActiveLoader(null)
	}
	const onRemove = (key) => {
		if(images.map.delete(key)){
			setImages({...images})
		}
	}
	const getImages = ()=>{
		let elems = [], itemData = {}
		if(!auth){
			itemData.tid = queryParams.get("tid")
			itemData.sid = queryParams.get("sid")
		}else itemData.auth = auth
		if(mode === "single"){
			if(images.map.size === 0) return elems
			let [data] = images.map.entries()
			elems.push(<Grid xs={12} sm={6} key={data[0]}><ImageItem data={data[1]} onRemove={onRemove} onToggleCrop={toggleCrop} onToggleBgRemover={toggleBgRemover} {...itemData} disable={{disableCopies,disableHeight,setDisableHeight}}/></Grid>)
		}else images.map.forEach((data,idx)=>{
			elems.push(<Grid xs={12} sm={6} key={idx}><ImageItem data={data} onRemove={onRemove} onToggleCrop={toggleCrop} onToggleBgRemover={toggleBgRemover} {...itemData}/></Grid>)
		})
		return elems
	}
	const onFileUploaded = () => {
		setStatus((status)=>{
			status.progress.completed++
			let {completed,total} = status.progress
			if(completed === total){
				status.step = 3
				console.log({statusData: status.data})
				let body = {_id: status.data._id}
				if(!auth){
					body.tid = queryParams.get("tid")
					body.sid = queryParams.get("sid")
				}
				http.post({url:"rectpack/finish_canvas",body,auth}).then(res=>{
					console.log({res})
					if(res.status === 200) onFinish("success","Proceso finalizado")
					else onFinish("warning",res.data.msg ? res.data.msg : JSON.stringify(res.data))
					setStatus((status)=>{
						status.step = 4
						status.data.files.forEach(val=>{delete val.blob})
						return {step: 4, data:{_id: status.data._id, files: status.data.files}}
					})
				})
			}
			return {...status}
		})
	}
	const getStatusImages = () => {
		if(status.step === 1) return <Box sx={{m:"auto",display:"flex",flexDirection:"column",alignItems:"center", overflow:"hidden"}}>
			<Typography fontSize="1.2rem">Solicitando datos del trabajo...</Typography>
			<CircularProgress/>
		</Box>
		return <TableContainer component={Paper} sx={{width:"100%",height:"fit-content",mx:"0.5rem",td:{textAlign:"center",p:"0.5rem"},th:{textAlign:"center",p:"0.5rem"}}}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell colSpan={5}>{`Trabajo ${status.data._id}`}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell sx={{textAlign:"left !important"}}>Archivo</TableCell>
						<TableCell>Ancho</TableCell>
						<TableCell>Alto</TableCell>
						<TableCell>Copias</TableCell>
						<TableCell>Estado</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{status.data.files.map((val,idx)=>{
						console.log({val,idx})
						let _id = val[1]
						val = val[0]
						let imgElem
						if(val.url) imgElem = <UploadingImage _id={_id} url={val.url} name={val.name} onUpload={onFileUploaded} temp={!auth ? {tid: queryParams.get("tid"), sid: queryParams.get("sid")} : undefined} auth={auth}/>
						else imgElem = <UploadingImage _id={_id} file={val.blob} name={val.name} onUpload={onFileUploaded} temp={!auth ? {tid: queryParams.get("tid"), sid: queryParams.get("sid")} : undefined}auth={auth}/>
						return <TableRow key={`up_file_${idx}`}>
							<TableCell sx={{textAlign:"left !important"}}>{val.name}</TableCell>
							<TableCell>{(val.width * ms.pxToCm).toFixed(2)} cm</TableCell>
							<TableCell>{(val.height * ms.pxToCm).toFixed(2)} cm</TableCell>
							<TableCell>{val.copies ? val.copies : 1}</TableCell>
							<TableCell>{imgElem}</TableCell>
						</TableRow>
					})}
				</TableBody>
			</Table>
		</TableContainer>
	}
	const switchMode = (ev,mode)=>{
		if(mode !== null) setMode(mode)
	}
	const onFilesSelected = (ev) => {
		const files = ev.target.files
		setImages({map: images.map, updating: true})
		checkImages(files).then(()=>{
			setImages({map: images.map, updating: false})
		})
		ev.target.files = null
	}
	const checkImages = async (files) => {
		for(let file of files){
			let imgKey = `${file.lastModified}_${file.size}_${file.name}`
			let img = images.map.get(imgKey)
			if(!img){
				images.map.set(imgKey,{file,loader:undefined})
			}
		}
	}
	const updateName = (ev) => {
		setCanvasName(errorValidator.validate("name",ev.target.value))
	}
	const updateDesc = (ev) => {
		setCanvasDesc(errorValidator.validate("desc",ev.target.value))
	}
	const updatePhone = (ev) => {
		setUserPhone(errorValidator.validate("phone",ev.target.value))
	}
	const updateWidth = (ev) => {
		setCanvasWidth(errorValidator.validate("width",ev.target.value))
	}
	const updateHeight = (ev) => {
		const val = ev.target.value
		if(val === ""){
			setDisableCopies(false)
		}else if(!disableCopies) setDisableCopies(true)
		setCanvasHeight(errorValidator.validate("height",val))
	}
	const updateMargin = (ev) => {
		setImagesMargin(errorValidator.validate("margin",ev.target.value))
	}
	const onFinish = (severity,message)=>{
		setMessage({type: severity, val: message})
		setShowSnackbar(true)
		setLoading(false)
	}

	const initCanvas = (stateData, body)=>{
		setStatus({step:1, progress:{completed:0, total: stateData.length}})
		http.post({url:"rectpack/init_canvas",body, auth}).then(res=>{
			if(res.status === 500 && !res.statusText){
				console.log(res.error)
				return onFinish("error", res.error.message === "Failed to fetch" ?
				"No se pudo conectar con el servidor"
				:
				res.error.message)
			}
			if(res.status === 502) return onFinish("error", "Se perdió la conexión con el servidor")
			if(res.status === 400) return onFinish("error", "Error en la solicitud")
			if(res.status === 401) return onFinish("error", "No autorizado")
			if(res.status === 403) return onFinish("error", "Prohibído")
			if(res.status === 404) return onFinish("error", "No encontrado")
			if(res.status === 200){
				if(res.data === "Invalid data") return onFinish("error","Los datos proporcionados son inválidos")
				else if(res.data === "Not enough space to sort into the canvas") return onFinish("error","Las dimensiones del trabajo no son suficientes para las imágenes")
				else if(res.data === "ok" || res.data === "Ok") return onFinish("success","Proceso finalizado")
				else if(typeof res.data === "object"){
					let doc = res.data.doc, imgs = res.data.imgs
					for(let i = 0; i < imgs.length; i++){
						let img = imgs[i]
						stateData[i][1] = img[0]
					}
					setStatus((status)=>{
						status.data = {files: stateData, _id: doc._id}
						status.step = 2
						return {...status}
					})
				}
			}else{
				console.log(res)
				onFinish("warning",typeof res.data === "string" ? res.data : res.data.msg ? res.data.msg : res.statusText)
			}
		})
	}

	async function onSubmitStart(){
		let body = {imgs: [], names: []}
		if(!auth){
			body.tid = queryParams.get("tid")
			body.sid = queryParams.get("sid")
			body.name = canvasName
			if(canvasDesc) body.desc = canvasDesc
			body.phn = userPhone
		}
		const stateData = []
		if(imagesMargin) body.m = Math.ceil((imagesMargin/10) * ms.cmToPx)
		body.w = Math.ceil(canvasWidth * ms.cmToPx)
		if(mode === "single"){
			body.mode = 1
			body.h = Math.ceil(canvasHeight * ms.cmToPx)
			//let [item] = images.map.values()
			//let loader = item.loader
		}else body.mode = 0
		//const asyncRequests = []
		//let idx = 0
		for(let val of images.map){
			let item = val[1], name
			if(!item.file) name = item.name
			else name = item.file.name
			let loader = item.loader
			if(loader && loader.state === 2){
				let img = [Math.ceil(loader.width), Math.ceil(loader.height),[1,0,0]]
				//if(loader.copies) img.push(loader.copies)
				if(loader.copies) img[2][0] = loader.copies
				/*let aidx = null
				if(loader.nobg){
					img[2][1] = 1
					aidx = asyncRequests.length
					asyncRequests.push({idx, file: loader.file, name, nobg: 1, enha: 0})
				}
				if(loader.enha){
					img[2][2] = 1
					if(aidx !== null){
						asyncRequests[aidx].enha = 1
					}else{
						asyncRequests.push({idx, file: loader.file, name: item.file.name, nobg: 0, enha: 1})
					}
				}*/
				body.imgs.push(img)
				body.names.push(name)
				/*if(!loader.file){
					stateData.push([{name: item.file.name, width: loader.width, height: loader.height, copies: loader.copies, url: loader.url},null,0])
				}else{
					stateData.push([{name: item.file.name, width: loader.width, height: loader.height, copies: loader.copies, blob: loader.file},null,0])
				}*/
				stateData.push([{name, width: loader.width, height: loader.height, copies: loader.copies, blob: loader.file},null,0])
			}
			//idx++
		}
		/*if(asyncRequests.length > 0){
			setApiStatus({requests: asyncRequests, stateData, body, pending: asyncRequests.length, done: 0})
			return
		}*/
		if(canvasDoc){
			try{
				let body = {_id: canvasDoc._id}
				if(!auth){
					body.tid = queryParams.get("tid")
					body.sid = queryParams.get("sid")
				}
				let res = await http.post({url:"rectpack/remove_items",body,auth}).then()
				if(res.status !== 200){
					console.error(res.data)
					console.error(res.status)
					return onFinish("error","Ocurrió un error al intentar reiniciar el trabajo")
				}
			}catch(err){
				console.error(err)
				return onFinish("error","Ocurrió un error al intentar reiniciar el trabajo")
			}
			body._id = canvasDoc._id
		}
		initCanvas(stateData,body)
	}

	/*useEffect(()=>{
		if(!apiStatus) return
		if(apiStatus.step === 1 && apiStatus.done === 0) return
		if(apiStatus.done === 0){
			console.log(apiStatus)
			const promises = []
			for(let request of apiStatus.requests){
				request.tries = 0
				function nobg(enha,resolve,reject){
					console.log("nobg->")
					let body = request.body
					if(!body){
						body = new FormData()
						body.append("img", request.file, request.name)
					}
					http.post({url:"rectpack/mod_nobg",body}).then(res=>{
						if(res.status === 200){
							request.tries = 0
							nobg_verify(res.data.task_id,enha,resolve,reject)
						}else{
							if(request.tries < 5){
								request.tries++
								setTimeout(()=>{
									nobg(enha,resolve,reject)
								},500)
							}else return reject("Failed too many tries")
						}
					})
				}
				function enha(url,resolve,reject){
					console.log("enha->")
					let body = request.body
					if(!body){
						if(url){
							body = {url}
						}else{
							body = new FormData()
							body.append("img", request.file, request.name)
						}
					}
					http.post({url:"rectpack/mod_enha",body}).then(res=>{
						if(res.status === 200){
							request.tries = 0
							enha_verify(res.data.task_id,resolve,reject)
						}else{
							if(request.tries < 5){
								request.tries++
								setTimeout(()=>{
									enha(url,resolve,reject)
								},500)
							}else return reject("Failed too many tries")
						}
					})
				}
				function nobg_verify(task_id,mod_enha,resolve,reject){
					http.post({url:"rectpack/verify_nobg",body:{task_id}}).then(res=>{
						if(res.status === 200){
							let url = res.data.url
							if(mod_enha){
								request.tries = 0
								request.body = undefined
								return enha(url,resolve,reject)
							}
							let width = res.data.width
							let height = res.data.height
							resolve({idx:request.idx,url,width,height})
						}else{
							if(res.status !== 400){
								reject(res.data)
							}
							if(request.tries < 10){
								request.tries++
								setTimeout(()=>{
									nobg_verify(task_id,mod_enha,resolve,reject)
								},500)//3000)
							}else{
								reject("Too many tries on verify:"+res.data.msg)
							}
						}
					})
				}
				function enha_verify(task_id,resolve,reject){
					http.post({url:"rectpack/verify_enha",body:{task_id}}).then(res=>{
						if(res.status === 200){
							let url = res.data.url
							let width = res.data.width
							let height = res.data.height
							resolve({idx: request.idx, url, width, height})
						}else{
							if(res.status !== 400){
								reject(res.data)
							}
							if(request.tries < 10){
								request.tries++
								setTimeout(()=>{
									enha_verify(task_id,resolve,reject)
								},3000)
							}else{
								reject("Too many tries on verify:"+res.data.msg)
							}
						}
					})
				}
				promises.push(new Promise(async (resolve,reject)=>{
					if(request.nobg) nobg(request.enha,resolve,reject)
					else if(request.enha) enha(null,resolve,reject)
				}))
			}
			Promise.all(promises).then(res=>{
				console.log("All promises have been resolved")
				console.log({res})
				let imgs = apiStatus.body.imgs, stateData = apiStatus.stateData, results = []
				for(let rslt of res){
					let idx = rslt.idx
					results.push({idx, previousWidth: imgs[idx][0], previousHeight: imgs[idx][1], width: rslt.width, height: rslt.height, url: rslt.url, copies: imgs[idx][2][0], name: stateData[idx][0].name})
					imgs[idx][0] = rslt.width
					imgs[idx][1] = rslt.height
					stateData[idx][0].url = rslt.url
					delete stateData[idx][0].blob
				}
				apiStatus.step = 1
				apiStatus.results = results
				setApiStatus({...apiStatus})
			})
			return
		}
		if(apiStatus.done === apiStatus.pending){
			let stateData = apiStatus.stateData, body = apiStatus.body
			setStatus({step:1, progress:{completed:0, total: stateData.length}})
			http.post({url:"rectpack/init_canvas",body}).then(res=>{
				if(res.status === 500 && !res.statusText){
					console.log(res.error)
					return onFinish("error", res.error.message === "Failed to fetch" ?
					"No se pudo conectar con el servidor"
					:
					res.error.message)
				}
				if(res.status === 502) return onFinish("error", "Se perdió la conexión con el servidor")
				if(res.status === 400) return onFinish("error", "Error en la solicitud")
				if(res.status === 401) return onFinish("error", "No autorizado")
				if(res.status === 403) return onFinish("error", "Prohibído")
				if(res.status === 404) return onFinish("error", "No encontrado")
				if(res.status === 200){
					if(res.data === "Invalid data") return onFinish("error","Los datos proporcionados son inválidos")
					else if(res.data === "Not enough space to sort into the canvas") return onFinish("error","Las dimensiones del trabajo no son suficientes para las imágenes")
					else if(res.data === "ok" || res.data === "Ok") return onFinish("success","Proceso finalizado")
					else if(typeof res.data === "object"){
						let doc = res.data.doc, imgs = res.data.imgs
						for(let i = 0; i < imgs.length; i++){
							let img = imgs[i]
							stateData[i][1] = img[0]
						}
						setStatus((status)=>{
							status.data = {files: stateData, id: doc.id}
							status.step = 2
							return {...status}
						})
					}
				}else{
					console.log(res)
					onFinish("warning",typeof res.data === "string" ? res.data : res.data.msg ? res.data.msg : res.statusText)
				}
			})
			setApiStatus()
		}
	},[apiStatus])*/

	const submit = () => {
		if(errors.width || (mode === "single" && errors.height) || errors.margin) return
		if(!canvasWidth/* || (mode === "single" && !canvasHeight)*/){
			if(!canvasWidth) errorValidator.validate("width")
			if(!auth && (!canvasName || !userPhone)){
				if(!canvasName) errorValidator.validate("name")
				if(!userPhone) errorValidator.validate("phone")
			}
			//if(mode === "single" && !canvasHeight) errorValidator.validate("height")
			return
		}
		if(!auth && (!canvasName || !userPhone)){
			if(!canvasName) errorValidator.validate("name")
			if(!userPhone) errorValidator.validate("phone")
			return
		}
		if(images.map.size === 0) return
		setLoading(true)
		onSubmitStart().then()
	}

	const onRestore = () => {
		const canvas = canvasRef.current
		const context = canvas.getContext("2d")
		console.log(activeLoader)
		canvas.width = activeLoader.img.width
		canvas.height = activeLoader.img.height
		context.drawImage(activeLoader.img,0,0)
	}
	const onSave = () => {
		const canvas = canvasRef.current
		activeLoader.updateImage(canvas)
		toggleCrop()
	}
	const onSaveBg = () => {
		//const canvas = canvasRef.current
		//activeLoader.updateImage(canvas)
		toggleBgRemover()
	}
	const canvasInputs = [
		{id:"name",label:"Nombre",input:updateName,val:canvasName},
		{id:"desc",label:"Descripción",input:updateDesc,val:canvasDesc},
		{id:"phone",label:"Teléfono",type:"tel",input:updatePhone,val:userPhone},
		{id:"width",label:"Ancho del trabajo",type:"number",options:[
			{val:28.5,label:"28,5 cm (DTF)"},
			{val:31.5,label:"31,5 cm (DTF)"},
			{val:58.5,label:"58,5 cm (DTF)"},
			{val:61.5,label:"61,5 cm (DTF)"},
			{val:107,label:"107 cm (Sublimación)"},
			{val:157,label:"157 cm (Sublimación)"},
			{val:160,label:"160 cm (Sublimación)"}
		],end:"cm",input:updateWidth,val:canvasWidth, editable: true},
		{id:"height",label:"Alto del trabajo",type:"number",showOn:"single",end:"cm",input:updateHeight,val:canvasHeight ,disabled: disableHeight},
		{id:"margin",label:"Margen entre imágenes",type:"number",end:"mm",input:updateMargin,val:imagesMargin, tooltip:"El “margen entre imágenes” es la distancia que se conserva alrededor de cada imagen que agregas al sistema. Si deseas agregar varias imágenes, el margen de cada una se sumará a la separación total"}
	]
	function getInputs(){
		const defaultSx = {transition: "0.2s linear",position:"relative", mt: "0.5rem", mb: "0.9rem", width: inputWidth, height: "2.5rem"}
		const comps = []
		let i = 0
		if(auth) i = 3
		for(i; i < canvasInputs.length; i++){
			let data = canvasInputs[i], sx = {...defaultSx}
			if(data.showOn){
				if(mode !== data.showOn){
					sx.height = 0
					sx.mt = 0
					sx.mb = 0
				}
			}
			if(!showInputs){
				sx.height = 0
				sx.mt = 0
				sx.mb = 0
			}
			const key = `canvasInput${i}`
			const bsx = {width:"100%",overflow:"hidden",height:"auto",display:"flex",justifyContent:"center", opacity: data.disabled ? 0.5 : 1}
			if(data.editable) comps.push(<Box key={key} sx={bsx}><EditableCanvasInput options={data.options} sx={sx} id={data.id} val={data.val} label={data.label} onInput={data.input} end={data.end} type={data.type} err={errors[data.id] ? errorValidator.msg(data.id) : undefined}/></Box>)
			else if(data.tooltip) comps.push(<Box key={key} sx={bsx}><TooltipCanvasInput options={data.options} tooltip={data.tooltip} sx={sx} id={data.id} val={data.val} label={data.label} onInput={data.input} end={data.end} type={data.type} err={errors[data.id] ? errorValidator.msg(data.id) : undefined} disabled={data.disabled}/></Box>)
			else comps.push(<Box key={key} sx={bsx}><CanvasInput options={data.options} sx={sx} id={data.id} val={data.val} label={data.label} onInput={data.input} end={data.end} type={data.type} err={errors[data.id] ? errorValidator.msg(data.id) : undefined} disabled={data.disabled}/></Box>)
		}
		return comps
	}

	function renderCanvasInputs(){
		return [
			<IconButton key="inputsExpand" size="small" sx={{transition: "transform 0.2s linear", transform: showInputs ? "" : "rotateX(180deg)"}} onClick={()=>{
				setShowInputs(!showInputs)
			}}><ExpandMoreIcon/></IconButton>,
			<Box key="inputsExtended" sx={{transition:"0.2s linear", overflow: showInputs ? "visible":"hidden", display:"flex",width:"100%",flexDirection:"column", "b":{transition: "0.2s ease-in-out"}}} height="fit-content">
				{getInputs()}
				{/* <CanvasInput sx={{position:"relative",mt:0,width: inputWidth}} id="canvasWidth" val={canvasWidth} label="Ancho del canvas"
				end="cm" err={errors.width ? errorValidator.msg("width",canvasWidth) : undefined} onInput={updateWidth}/>
				<CanvasInput sx={heightSx} id="canvasHeight" val={canvasHeight} label="Alto del canvas" end="cm"
				err={errors.height ? errorValidator.msg("height",canvasHeight) : undefined} onInput={updateHeight}/>
				<CanvasInput sx={{mt:2,width: inputWidth,position:"relative"}} id="imagesMargin" val={imagesMargin} label="Margen por imagen"
				end="cm" err={errors.margin ? errorValidator.msg("margin",imagesMargin) : undefined} onInput={updateMargin}/> */}
				
			</Box>,
			<Box key="inputsContracted" sx={{display:"flex", flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
				<Box sx={{display:"flex",flexDirection:"row",transition: "0.2s linear", width: showInputs ? "0%" : "100%",
				"> div":{position:"relative", transition: "0.2s linear"}}}>
					<Box sx={{width: mode === "multi" ? "50%" : "33.33%", overflow:"hidden"}}>
						<TextField fullWidth={true} sx={{...defaultAprops.number,textAlign:"left"}} select defaultValue=""
						id="bottomCanvasWidth" label="Ancho (cm)" type="number" variant="standard" autoComplete="off"
						value={canvasWidth} onChange={updateWidth} error={errors.width ? true : false}>
							{canvasInputs[3].options.map((val,idx)=><MenuItem key={`cw_hidOpts${idx}`} value={val.val}>
								{val.label}
							</MenuItem>)}
						</TextField>
						<Box sx={(theme)=>({display:"flex",color:theme.palette.error.main,position:"absolute",top:0,right:"1rem"})}>
							{errors.width ? errorValidator.msg("width",1) : ""}
						</Box>
					</Box>
					<Box sx={{width: mode === "multi" ? "0%" : "33.33%", overflow:"hidden", opacity: disableHeight ? 0.5 : 1}}>
						<TextField fullWidth={true} sx={defaultAprops.number} disabled={disableHeight}
						id="bottomCanvasHeight" label="Alto (cm)" type="number" variant="standard" autoComplete="off"
						value={canvasHeight} onInput={updateHeight} error={errors.height ? true : false}/>
						<Box sx={(theme)=>({display:"flex",color:theme.palette.error.main,position:"absolute",top:0,right:"1rem"})}>
							{errors.height ? errorValidator.msg("height",1) : ""}
						</Box>
					</Box>
					<Box sx={{width: mode === "multi" ? "50%" : "33.33%", overflow:"hidden"}}>
						<TextField fullWidth={true} sx={defaultAprops.number}
						id="bottomImagesMargin" label="Margen (mm)" type="number" variant="standard" autoComplete="off"
						value={imagesMargin} onInput={updateMargin} error={errors.margin ? true : false}/>
						<Box sx={(theme)=>({display:"flex",color:theme.palette.error.main,position:"absolute",top:0,right:"1rem"})}>
							{errors.margin ? errorValidator.msg("margin",1) : ""}
						</Box>
					</Box>
				</Box>
				<Box sx={{position:"relative"}}>
					<Button variant="contained" disabled={loading} onClick={submit}>
						Confirmar
					</Button>
					{loading && <CircularProgress
					size={24}
					sx={{
						position:"absolute",
						top: "50%",
						left: "50%",
						marginTop:"-12px",
						marginLeft:"-12px"
					}}/>}
				</Box>
			</Box>
		]
	}

	function renderCanvasTopOptions(){
		return <>
			<ToggleButtonGroup
			size="small" color="primary" value={mode} exclusive onChange={switchMode}
			sx={{bgcolor:"white",boxShadow:3,minHeight:"2.25rem"}} aria-label="image mode">
				<ToggleButton value="multi">
					Multiples imágenes
				</ToggleButton>
				<ToggleButton value="single">
					Única imágen repetida
				</ToggleButton>
			</ToggleButtonGroup>
			{match ? <Button sx={{width:"12.4rem"}} component="label" variant="contained" startIcon={<AddPhotoAlternateIcon/>}>
				{mode === "multi" ? "Agregar imágenes" : "Agregar imágen"}
				<VisuallyHiddenInput type="file" accept="image/png,image/jpeg,image/bmp,image/tiff,image/svg+xml" multiple={mode === "multi"} onInput={onFilesSelected}/>
			</Button> : <ContainedIconButton component="label" sx={{height:"2.25rem", width:"2.25rem", my:"auto"}}>
				<VisuallyHiddenInput type="file" accept="image/png,image/jpeg,image/bmp,image/tiff,image/svg+xml" multiple={mode === "multi"} onInput={onFilesSelected}/>
				<AddPhotoAlternateIcon/>
			</ContainedIconButton>}
		</>
	}

	function renderScreen(){
		//console.log({apiStatus,status})
		if(apiStatus?.step === 1){
			let elems = []
			apiStatus.results.forEach((data,idx)=>{
				console.log({data})
				elems.push(<Grid xs={12} sm={6} key={idx}><ImageResult data={data}/></Grid>)
			})
			return elems
		}
		if(status.step === 0){
			if(images.map.size === 0) return <IconButton component="label" sx={{width:"12rem",height:"12rem", "& svg":{width:"8rem",height:"8rem"}}}>
				<VisuallyHiddenInput type="file" accept="image/png,image/jpeg,image/bmp,image/tiff,image/svg+xml" multiple={mode === "multi"} onInput={onFilesSelected}/>
				<AddPhotoAlternateIcon/>
			</IconButton>
			return <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{
				transition:"0.2s linear", paddingBottom: "1rem",
				mt:1, mx:1, mb:"1px", width:"100%"}}>{getImages()}
			</Grid>
		}
		return getStatusImages()
	}
	function renderScreenBottom(){
		if(apiStatus?.step === 1) return <Button onClick={()=>{
			//return console.log(apiStatus.results)
			for(let val of apiStatus.results){
				console.log({val})
				let idx = val.idx
				let img = apiStatus.body.imgs[idx]
				let stateData = apiStatus.stateData[idx][0]
				let loader = val.loader
				img[0] = Math.ceil(loader.width)
				img[1] = Math.ceil(loader.height)
				stateData.width = loader.width
				stateData.height = loader.height
				delete stateData.blob
			}
			apiStatus.done = apiStatus.pending
			apiStatus.step = undefined
			setApiStatus({...apiStatus})
		}}>
			Confirmar
		</Button>
		if(status.step === 0) return renderCanvasInputs()
		if(status.step === 1) return undefined
		if(status.step < 4) return <CircularProgress sx={{m:2}}/>
		if(!auth) return <Button sx={{m:2}} variant="contained" onClick={()=>{
			navigate(`../canvas_temp?tid=${queryParams.get("tid")}&sid=${queryParams.get("sid")}`,{replace:true})
			reset()
		}}>
			Ver Detalles
		</Button>
		return <Button sx={{m:2}} variant="contained" onClick={()=>{
			setStatus({step:0})
			setImages({map: new Map(), updating: false })
			setCanvasWidth("")
			setCanvasHeight("")
			setImagesMargin("")
			setErrors({width: "req",height: "req",margin:undefined})
			setActiveLoader()
		}}>Nuevo Trabajo</Button>
	}

	function renderUnfinishedTable(){
		console.log(canvasDoc)
		return <TableContainer component={Paper} sx={{width:"100%",height:"fit-content",maxHeight:"15vh",mx:"0.5rem",td:{textAlign:"center",py:"0.1rem"},th:{textAlign:"center",py:"0.1rem"}}}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell colSpan={4}>Imagenes faltantes</TableCell>
					</TableRow>
					<TableRow>
						<TableCell sx={{textAlign:"left !important"}}>Nombre</TableCell>
						<TableCell>Ancho</TableCell>
						<TableCell>Alto</TableCell>
						<TableCell>Copias</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>{canvasDoc.unfinished.map((val,idx)=>{
					console.log({val,idx})
					return <TableRow key={`uImg${idx}`}>
						<TableCell sx={{textAlign:"left !important"}}>{val.name}</TableCell>
						<TableCell>{val.w}</TableCell>
						<TableCell>{val.h}</TableCell>
						<TableCell>{val.qty}</TableCell>
					</TableRow>
				})}</TableBody>
			</Table>
		</TableContainer>
	}

	return <Box sx={{
		position:"relative",
		width:"100%",
		height:"100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent:"space-between",
		overflow:"hidden"
	}}>
		<Crop showCrop={showCrop} onClose={toggleCrop} onRestore={onRestore} onSave={onSave} ref={canvasRef} ctx={context} zoom={zoom} setZoom={setZoom} isMobile={isMobile}/>
		<BackgroundRemover show={showBgRemover} image={activeLoader} onClose={toggleBgRemover} onSave={onSaveBg} isMobile={isMobile} bgremoveAllMethods={{value:bgremoveAllMethods,set:setBgremoveAllMethods}}/>
		<Box sx={{display:"flex",flexDirection:"row",justifyContent:"space-evenly",width:"100%",maxHeight:"2.25rem"}}>
			{status.step === 0 ? renderCanvasTopOptions() :
			<Box color="white" bgcolor="primary.main" boxShadow={2} borderRadius={1} p={1} fontWeight={500}>{mode === "multi" ? "MÚLTIPLES IMÁGENES" : "ÚNICA IMAGEN REPETIDA"}</Box>}
		</Box>
		<Box sx={{height:"auto", oferflow: "auto", display: "flex", width: "100%", mt: "0.25rem", justifyContent:"center"}}>{status.step === 0 && canvasDoc?.unfinished?.length > 0 && renderUnfinishedTable()}</Box>
		<Box sx={{height:"auto",overflow:"auto",display:"flex",width:"100%",mt:"0.25rem",justifyContent:"center"}}>
			{renderScreen()/*status.step === 0 ? 
			(images.map.size === 0 ? <IconButton component="label" sx={{width:"12rem",height:"12rem", "& svg":{width:"8rem",height:"8rem"}}}>
				<VisuallyHiddenInput type="file" multiple={mode === "multi"} onInput={onFilesSelected}/>
				<AddPhotoAlternateIcon/>
			</IconButton> : <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{
			transition:"0.2s linear", paddingBottom: "1rem",
			mt:1, mx:1, mb:"1px", width:"100%"}}>{getImages()}</Grid>) :
			getStatusImages()*/}
		</Box>
		<Box sx={{transition:"0.2s linear",width:"100%",display:"flex",flexDirection:"column",alignItems:"center",
		backgroundColor:"#fff",borderTop:"1px solid #dfdfdf"}}>
			{renderScreenBottom()}
		</Box>
		<Snackbar anchorOrigin={{horizontal: "left", vertical: "bottom"}}
		open={showSnackbar} onClose={()=>{setShowSnackbar(false)}}>
			<Alert sx={{width:"100%"}} onClose={()=>{setShowSnackbar(false)}} severity={message ? message.type : "info"}>{message?.val}</Alert>
		</Snackbar>
	</Box>
}
// const heightSx = {transition: "all 0.2s ease-in", width: inputWidth, ...(mode === "single" ? {mt: 2, transform: "scaleY(100%)", height:"2.5rem"}:{mt: 0, transform: "scaleY(0)", height:0})}
export default Editor;