import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useContext, useEffect, useState, forwardRef } from "react"
import { Context } from "../contexts/general"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import LinkIcon from '@mui/icons-material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import AccountIcon from "@mui/icons-material/AccountCircle"
//import {ReactComponent as VectorizerSVG} from "../media/vectorizer.ai.svg"
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider'
import ListItemIcon from '@mui/material/ListItemIcon'
import PriceIcon from "@mui/icons-material/PriceChange"
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import ImageIcon from "@mui/icons-material/Image"
import Logout from "@mui/icons-material/Logout"
import Create from "@mui/icons-material/Create"
import ViewList from "@mui/icons-material/ViewList"
import Settings from "@mui/icons-material/Settings"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Snackbar from "@mui/material/Snackbar"
import MuiAlert from "@mui/material/Alert"
import styled from "@mui/material/styles/styled"
import BarButton from "./basicComps/barButton"
import http from "../libs/http"
const Alert = forwardRef(function Alert(props, ref){
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props}/>
})
/*const VectorizerIcon = styled(VectorizerSVG)({
	width:"1.5rem",
	height:"1.5rem",
	fill:"rgba(0,0,0,0.54)"
})*/
const defaultSnack = {horizontal: "center", vertical: "bottom"}
function Root(){
	const location = useLocation()
	const navigate = useNavigate()
	const activePath = location.pathname.substring(location.pathname.lastIndexOf("/")+1)
	const [open, setOpen] = useState(false)
	const [openAccMenu, setOpenAccMenu] = useState(null)
	const [snack,setSnack] = useState({show: false, msg: "", type: "", ...defaultSnack})
	const handleDrawerOpen = () => {
		setOpen(true);
	};
	const {auth,userInfo,unsetAuth,setRedirect} = useContext(Context)
	const handleDrawerClose = () => {
		setOpen(false);
	};
	const toggleAccountMenu = (ev) => {
		if(openAccMenu) setOpenAccMenu(null)
		else setOpenAccMenu(ev.target)
	}
	const disableSnack = () => {
		setSnack((snack)=>{
			snack.show = false
			return {...snack}
		})
	}
	const enableSnack = (msg,type,{autohide,x,y} = {}) => {
		setSnack((snack)=>{
			snack.show = true
			snack.msg = msg
			snack.type = type ? type : "info"
			if(x) snack.horizontal = x
			else snack.horizontal = defaultSnack.horizontal
			if(y) snack.y = y
			else snack.vertical = defaultSnack.vertical
			if(autohide) snack.autohide = autohide
			else snack.autohide = undefined
			return {...snack}
		})
	}
	useEffect(()=>{
		let path = activePath.toLowerCase()
		//if(location.state) console.log("location state is",location.state)
		if(path === "rectpack" || (!auth && path === "home")) return navigate("signin",{replace:true,state:{from: path,msg:"ok"}})
		//if(!auth && activePath.toLowerCase() !== "signin") return navigate("signin",{replace:true,state:{from:activePath.toLowerCase(),msg:"ok"}})
	})
	/*const onFilesSelected = (ev) => {
		const files = ev.target.files
		setImages({map: images.map, updating: true})
		checkImages(files).then(()=>{
			setImages({map: images.map, updating: false})
		})
		ev.target.files = null
	}*/
	if(activePath === "signin") return <Outlet/>
	const profileMenuProps = {paper:{
		elevation: 0,
		sx: {
		  overflow: 'visible',
		  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
		  mt: 1.5,
		  '& .MuiAvatar-root': {
			width: 32,
			height: 32,
			ml: -0.5,
			mr: 1,
		  },
		  '&:before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: 0,
			right: 11,
			width: 10,
			height: 10,
			bgcolor: 'background.paper',
			transform: 'translateY(-50%) rotate(45deg)',
			zIndex: 0,
		  },
		},
	}}
	// const sideButtonSx = {"> p":{pointerEvents:"none",textOverflow:"hidden",whiteSpace:"nowrap",overflow:"hidden"}, cursor:"pointer", display:"flex", flexDirection: {xs:"column",md:"row"},alignItems:{xs:undefined,md:"center"}}
	const contentWidth = open ? {xs:"calc(100vw - 4rem)", md:"calc(100vw - 8rem)"} : "100vw"
	const marginLeft = {xs:"4rem", md:"8rem"}
	const translateWidth = {xs: "translateX(-4rem)",md:"translateX(-8rem)"}
	const appBarSx = {left: "0",boxShadow:2}
	if(!auth) appBarSx.display = "none"
	const sidebarOptions = [
		[["Crear trabajo","Crear"],"create",Create],
		[["Listar trabajos","Lista"],"list",ViewList],
		//[["Precios","Precios"],"pricing",PriceIcon],
		[["Vectorizar", "Vectorizar"],"vect",ImageIcon]
	]
	if(userInfo && userInfo.ut && (userInfo.ut === "ad" || userInfo.ut === "su")){
		sidebarOptions.push("",
			[["Link temporal","Temporal"],"temp",LinkIcon],
			[["Gestión","Gestión"],"management",ViewList],
			[["Registrar","Registrar"],"register",PersonAddIcon]
		)
	}
	return <>
		<AppBar sx={appBarSx} position='fixed'>
			<Toolbar sx={{height:"4rem"}}>
				<IconButton color="inherit" aria-label="menu" style={{position:"fixed",top:"0",left:"0",height: "4rem", width: "4rem", transition: "0.2s linear"}}
				sx={{ mr: 2, opacity: open ? 0 : 1 }} onClick={()=>{handleDrawerOpen()}}>
					<MenuIcon/>
				</IconButton>
				<Box>
					<IconButton edge="start" color="inherit" aria-label="menu" style={{zIndex: 2, height: "4rem", width: "4rem"}}
					sx={{position:"fixed",top:0,right:0}} onClick={toggleAccountMenu}>
						<AccountIcon fontSize="large"/>
					</IconButton>
					<Menu 
						sx={{mt:"2rem"}} id="menu-appbar"
						slotProps={profileMenuProps}
						anchorEl={openAccMenu}
						anchorOrigin={{vertical:"top",horizontal:"right"}}
						keepMounted
						transformOrigin={{vertical:"top",horizontal:"right"}}
						open={Boolean(openAccMenu)}
						onClose={toggleAccountMenu}>
						<MenuItem onClick={toggleAccountMenu}>
							<ListItemIcon fontSize="small"><AccountIcon/></ListItemIcon>Perfil
						</MenuItem>
						<Divider />
						<MenuItem onClick={toggleAccountMenu}>
							<ListItemIcon fontSize="small"><Settings/></ListItemIcon>Configuración
						</MenuItem>
						<MenuItem onClick={()=>{ // Sign Out / Logout
								http.get({url:"web/session/logout"}).then(res=>{
									//console.log("OK, RESULTS")
									//console.log({res})
									unsetAuth()
									setRedirect("/rectpack/signin")
									return navigate("/rectpack/signin",{replace:true,state:{from: activePath}})
								}).catch(err=>{
									console.error(err)
									unsetAuth()
									setRedirect("/rectpack/signin")
									return navigate("/rectpack/signin",{replace:true,state:{from: activePath}})
								})
								if(openAccMenu) setOpenAccMenu(null)
								if(open) setOpen(false)
							}}>
							<ListItemIcon fontSize="small"><Logout/></ListItemIcon>Cerrar Sesión
						</MenuItem>
					</Menu>
				</Box>
				<Typography variant="h6" component="div" sx={{ display:{xs: "none", sm: "flex"}, ml: open ? {xs:"4rem", md:"8rem"} : "2.6rem", transition: "0.2s linear"}}>
					Armalo Studio
				</Typography>
			</Toolbar>
		</AppBar>
		<Box sx={{
			transition: "0.2s linear",
			boxSizing: "border-box",
			width: contentWidth,
			height: "100%", //`calc(100% - ${auth ? "4.5rem" : "0.5rem"})	`,
			pt: auth ? "4.5rem" : "0.5rem",
			bgcolor: "rgba(0,0,0,0.1)",
			overflowY: "auto",
			marginLeft: open ? marginLeft : 0}}>
				<Outlet context={{snack,setSnack: enableSnack}}/>
		</Box>
		<Box sx={{
		width: marginLeft,
		transition: "0.2s ease-in-out",
		borderTopRightRadius:"1rem",
		borderBottomRightRadius:"1rem",
		boxShadow:4,
		height: "100vh",
		position: "fixed",
		top:0,
		left:0,
		backgroundColor:"#fff",
		zIndex:1101,
		transform: open ? "translateX(0)" : translateWidth,
		"& .MuiButtonBase-root":{color:"rgba(0,0,0,0.54)"}}}>
			<Button aria-label="menu"
			sx={{width:"100%", height:"4rem"}} onClick={()=>{handleDrawerClose()}}>
				<MenuIcon/>
			</Button>
			<Divider/>
			{sidebarOptions.map((val,idx)=>{
				if(val === "") return <Divider key={`divider${idx}`}/>
				return <BarButton key={`sideBtn${idx}`} icon={val[2]} text={val[0]} onClick={()=>{navigate(val[1])}}/>})}
			{/*<Box sx={sideButtonSx} onClick={()=>{navigate("create")}}> <BarButton icon={VectorizerIcon} text={["Vectorizar im.","Vectorizar"]} onInput={selectSVG}/>
				<IconButton><Create/></IconButton>
				<Typography fontSize="small" sx={{
					"&::before":{content:'"Crear"'},
					"&::after":{content:{xs: '""', sm:'""', md:'" Trabajo"'}}}}/>
			</Box>
			<Box sx={sideButtonSx} onClick={()=>{navigate("list")}}>
				<IconButton><ViewList/></IconButton>
				<Typography fontSize="small" sx={{
					"&::before":{content:'"Lista"'},
					"&::after":{content:{xs: '""', sm:'""', md:'"r Trabajo"'}}}}/>
			</Box>*/}
		</Box>
		<Snackbar anchorOrigin={{horizontal: snack.horizontal, vertical: snack.vertical}}
		open={snack.show} onClose={disableSnack} autoHideDuration={snack.autohide}>
			<Alert sx={{width:"100%"}} onClose={disableSnack} severity={snack.type ? snack.type : "info"}>{snack.msg}</Alert>
		</Snackbar>
	</>
}
export default Root;