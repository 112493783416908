import { useEffect, useRef, useState } from "react"
import Crop from "../editor/crop/index"
import { isMobile } from "react-device-detect"

const DEFAULT_WIDTH = 8000//1800
const DEFAULT_HEIGHT = 2048

/**
 * 
 * @param {CanvasRenderingContext2D} context 
 */
function doGradient(context,width,height,times){
	let i = 0, n = 0
	const rgb = [255,0,0]
	/**
	 * Order:
	 * g++ from 255,0,0 to 255,255,0
	 * r--
	 * b++
	 * g--
	 * r++
	 * b--
	 */
	while(n < times){
		let idx, step
		for(let j = 0; j < rgb.length; j++){
			let jf = ((rgb.length-1) === j ? 0 : j+1)
			//console.log(`rgb[${j}]=${rgb[j]}`)
			//console.log(`rgb[${jf}](j+1)=${rgb[jf]}`)
			if(rgb[j] === 0) continue
			if(rgb[j] === 255 && rgb[jf] === 255){ // reduce j, otherwise increase jf
				idx = j
				step = -1
				break
			}else{
				idx = jf
				step = 1
				break
			}
		}
		//console.log({n,idx,step})
		//console.log(rgb)
		for(i; i < ((n+1) * 256); i++){
			if(i > width) return
			context.fillStyle = `rgb(${rgb[0]},${rgb[1]},${rgb[2]})`
			context.fillRect(i,0,1,height)
			if(step > 0) rgb[idx] += 1
			else if(step < 0) rgb[idx] -= 1
			//console.log(`rgb[${idx}]`,rgb[idx])
		}
		if(rgb[idx] < 0) rgb[idx] = 0
		if(rgb[idx] > 255) rgb[idx] = 255
		n++
	}
}

function Test(){
	const canvasRef = useRef(null)
	const [zoom,setZoom] = useState(100)
	/** @type {[CanvasRenderingContext2D,Function]} */
	const [context,setContext] = useState()
	const onRestore = () => {
		const canvas = canvasRef.current
		if(!canvas) return
		canvas.width = DEFAULT_WIDTH
		canvas.height = DEFAULT_HEIGHT
		doGradient(context,DEFAULT_WIDTH,DEFAULT_HEIGHT,99)
		return setContext(context)
	}
	const onSave = () => {

	}
	useEffect(()=>{
		if(!context){
			/**
			 * @type {HTMLCanvasElement}
			 */
			const canvas = canvasRef.current
			if(!canvas) return
			canvas.width = DEFAULT_WIDTH
			canvas.height = DEFAULT_HEIGHT
			const context = canvas.getContext("2d")
			doGradient(context,canvas.width,canvas.height,99)
			return setContext(context)
		}
	},[context])
	return <Crop showCrop={true} onClose={()=>{}} onRestore={onRestore} onSave={onSave} ref={canvasRef} ctx={context} zoom={zoom} setZoom={setZoom} isMobile={isMobile}/>
}
export default Test