import "./App.css"
import {RouterProvider, createBrowserRouter, redirect} from 'react-router-dom';
import { useContext } from 'react';
import {Context} from "./contexts/general"
import Home from "./modules/home"
import SignIn from "./modules/signin"
import Root from "./modules/root"
import Editor from "./modules/editor";
import List from "./modules/list";
import Temporal from "./modules/temporal"
import Test from "./modules/testing";
import Vectorizer from "./modules/vectorizer";
import Management from "./modules/management";
import Main from "./modules/main";
import SignUp from "./modules/signup";
import ConfirmSignUp from "./modules/confirmSignup";
import Pricing from "./modules/pricing";
import Register from "./modules/management/register";

const routes = {
	root:"rectpack",
	get: function(route){
		return "/" + this.root + (route ? "/" + route : "")
	}
}

function App() {
	const {auth} = useContext(Context)
	function homeOnAuth(){
		if(auth) return redirect(routes.get("create"))
		return null
	}
	function requiresAuth(){
		if(!auth) return redirect(routes.get("signin"))
		return null
	}
	const router = createBrowserRouter([{
		path:"/", element: <Main/>,
		children:[
			{path:"confirm_signup",element:<ConfirmSignUp/>},
			{path:"confirm_tsignup",element:<ConfirmSignUp/>},
			{path:"restore_password",element:<ConfirmSignUp/>},
			{path:"rectpack", element:<Root/>,children:[
				{path:"home",element:<Home/>,loader:requiresAuth},
				{path:"signin",element:<SignIn/>,loader:homeOnAuth},
				{path:"signup",element:<SignUp/>,loader:homeOnAuth},
				{path:"create",element:<Editor/>,loader:requiresAuth},
				{path:"list",element:<List/>,loader:requiresAuth},
				{path:"temp",element:<Temporal/>,loader:requiresAuth},
				{path:"vect",element:<Vectorizer/>,loader:requiresAuth},
				{path:"management",element:<Management/>,loader:requiresAuth},
				{path:"register",element:<Register/>,loader:requiresAuth},
				//{path:"pricing",element:<Pricing/>,loader:requiresAuth},
				{path:"create_temp",element:<Temporal/>},
				{path:"canvas_temp",element:<Temporal/>},
				{path:"test",element:<Test/>}
			]}
		]
	}])
	return <div className="App">
		<RouterProvider router={router}/>
	</div>
}
export default App;
