import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"

import IconButton from "@mui/material/IconButton"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import CloseIcon from "@mui/icons-material/Close"
import SaveIcon from "@mui/icons-material/Save"
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import CircularProgress from "@mui/material/CircularProgress"
import UploadIcon from "@mui/icons-material/Upload"
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import http from "../../../libs/http"
//import ImageLoader from "../components/imageLoader"
import "../../../css/scroll.css"
import { deleteCookie, setCookie } from "../../scripts/cookie"

const uri = "www.armalostudio.com"

const checkOptions = [
	["bria","Bria"],
	["inspyrenet","InsSPyRe"],
	["u2net","U2Net"],
	["tracer","Tracer"],
	["basnet","BASNet"],
	["deeplab","DeepLab"],
	["u2net_human_seg","U2Net human"],
	["ormbg","ORMBG"],
	["isnet-general-use","ISNET-DIS"],
	["isnet-anime","ISNET-Anime"]
]
/**
 * 
 * @param {{onSave: Function, onClose: Function, show: boolean, isMobile: boolean, image: ImageLoader}} param0 
 * @returns 
 */
const BackgroundRemover = ({onSave,onClose,show,isMobile,image,bgremoveAllMethods}) =>{
	const containerRef = useRef()
	const [url,setUrl] = useState("")
	const [state,setState] = useState(0)
	const [zoom,setZoom] = useState(100)
	const [loading,setLoading] = useState(false)
	const [dims,setDims] = useState({width:0,height:0,w:"0",h:"0"})
	const [checked,setChecked] = useState(()=>{
		const values = new Array(checkOptions.length)
		values.fill(true)
		return values
	}) 
	const [loadingMethods,setLoadingMethods] = useState(()=>{
		const values = new Array(checkOptions.length)
		values.fill(0)
		return values
	})
	const [images,setImages] = useState(()=>{
		const values = new Array(checkOptions.length)
		values.fill(null)
		return values
	})
	const [active,setActive] = useState(undefined)
	const [activeId,setActiveId] = useState()
	/*const submitFile = useCallback(async (file,)=>{

	})*/
	const submitFile = useCallback((idx)=>{
		const body = new FormData()
		body.append("method",checkOptions[idx][0])
		body.append("file",image.file)
		http.post({uri,url:"remove_background",body}).then(res=>{
			setLoadingMethods(loadingMethods => {
				if(res.status === 200) loadingMethods[idx] = 2
				else loadingMethods[idx] = 3
				return [...loadingMethods]
			})
			if(res.status !== 200) return
			const _url = URL.createObjectURL(res.data)
			setImages(images=>{
				images[idx] = _url
				return [...images]
			})
		})
		//setLoadingMethods(vals=>{vals[idx] = 1;return [...vals]})
	},[image])
	const submit = useCallback(()=>{
		const values = [...loadingMethods]
		values.fill(1)
		setLoading(true)
		setState(1)
		let i = 0
		for(let check of checked){
			const n = i++
			if(!check){
				values[n] = 4
				continue
			}
			submitFile(n)
		}
		setLoadingMethods(values)
	},[checked, loadingMethods, submitFile])
	useEffect(()=>{
		if(!show && url){
			URL.revokeObjectURL(url)
			setImages(images=>{
				for(let image of images){
					if(image) URL.revokeObjectURL(image)
				}
				images.fill(null)
				return [...images]
			})
			setChecked(values=>{values.fill(true);return [...values]})
			setActive()
			setActiveId()
			setUrl("")
			setDims({width:0,height:0,w:"0",h:"0"})
		}
		if(show && !url && image){
			setUrl(URL.createObjectURL(image.file))
			if(bgremoveAllMethods && bgremoveAllMethods.value){
				submit()
			}
		}
	},[url, image, show, bgremoveAllMethods, submit])
	useEffect(()=>{
		if(loading && state === 1){
			let count = 0, ok = 0//, err= 0
			for(let method of loadingMethods){
				if(method === 2 || method === 4){
					count++
					ok++
				}else if(method === 3){
					count++
					//err++
				}
			}
			if(count === 10){
				if(ok === count){
					setState(2)
					setActiveId(0)
					setActive(images[0])
				}else if(ok > 1){
					for(let i = 0; i < loadingMethods.length; i++){
						if(loadingMethods[i] === 2){
							setActiveId(i)
							setActive(images[i])
							break
						}
					}
				}
				setLoading(false)
			}
			/*if(count === 10){
				setLoading(false)
				setState(2)
			}*/
			return
		}
	},[images, loading, loadingMethods, state])
	useLayoutEffect(()=>{
		if(!show) return
		if(containerRef.current){
			if(dims.w !== "0" && dims.h !== "0") return
			let elem = containerRef.current
			/** @type {HTMLCanvasElement} */
			let width = elem.offsetWidth
			let height = elem.offsetWidth
			if(width === 0 || height === 0){
				if(show){
					setTimeout(()=>{
						let width = containerRef.current.offsetWidth
						let height = containerRef.current.offsetWidth
						if(width === 0 && height === 0) return
						setDims({width:0,height:0,w:"0",h:"0"})
					},300)
				}
				return
			}
			let proportion = width / height
			let imgProportion = image.width / image.height
			let w, h
			if(proportion > imgProportion){
				w = "auto"
				h = "100%"
			}else{
				w = "100%"
				h = "auto"
			}
			setDims({
				width: elem.offsetWidth,
				height: elem.offsetHeight,
				w,
				h
			})
		}
	}, [show, dims, image])
	const close = ()=>{
		if(loading) return
		setLoadingMethods(vals=>{
			vals.fill(0)
			return [...vals]
		})
		setState(0)
		onClose()
	}
	function countChecked(){
		let sum = 0
		checked.forEach((val)=>{
			if(val) sum++
		})
		return sum
	}
	const handleCheckAll = (ev) => {
		const values = [...checked]
		values.fill(ev.target.checked)
		setChecked(values)
	}
	const handleCheck = (ev,id) => {
		setChecked(checked => {
			checked[id] = ev.target.checked
			return [...checked]
		})
	}
	const checkedCount = countChecked()
	
	function renderBottomOptions(){
		if(state === 0) return <><Button variant="contained" size="small" onClick={submit}><UploadIcon/>Ejecutar</Button>
		<Box sx={{position:"absolute",bottom:"-1.5rem",right:"1rem"}}><FormControlLabel sx={{mr:0}} label="Usar todos los métodos por defecto" control={<Checkbox size="small" checked={bgremoveAllMethods && bgremoveAllMethods.value === "1"} onChange={()=>{
			if(bgremoveAllMethods){
				console.log("changing value...")
				if(bgremoveAllMethods.value){
					deleteCookie("all-bgremover-methods")
					bgremoveAllMethods.set()
				}else{
					bgremoveAllMethods.set("1")
					setCookie("all-bgremover-methods","1")
				}
			}else console.log("cant change values")
		}}/>}/></Box>
		</>
		if(state === 1){
			if(loading) return <Button variant="contained" size="small" disabled><UploadIcon/>Ejecutando...</Button>
			return <Button sx={{mx:"auto"}} varialt="contained" onClick={()=>{
				setState(2)
				for(let i = 0; i < loadingMethods.length; i++){
					if(loadingMethods[i] === 2){
						if(images[i]) setActive(images[i])
						setActiveId(i)
						break
					}
				}
			}}>Continuar</Button>
		}
		if(state === 2) return <Button variant="contained" onClick={()=>{if(!active) return;setLoading(true);image.update(active,()=>{onSave();setLoading(false);setState(0)})}} disabled={loading || !active}><SaveIcon/>Guardar</Button>
	}
	function renderActions(){
		return <Box sx={{width:"calc(100% - 2rem)",display:"flex",justifyContent:"flex-end",pl:"1rem"}}>
			<Tooltip title="Cerrar" placement="top" arrow>
				<IconButton onClick={close} size="small"><CloseIcon fontSize="inherit"/>
				</IconButton>
			</Tooltip>
		</Box>
	}
	function renderMethods(){
		if(state === 0) return <Box sx={{overflowX:"hidden",overflowY:"overlay",display:"flex",flexDirection:"column",pl:"1rem", width:"12rem"}}><FormControlLabel sx={{mr:0}} label="Métodos" control={<Checkbox checked={checkedCount > 0} indeterminate={checkedCount < checked.length && checkedCount > 0} onChange={handleCheckAll}/>}/>
			<Box className="scroll-thin" sx={{display:"grid",pl:"1.5rem",width:"max-content"}}>{checkOptions.map((val,idx)=>{
				return <FormControlLabel key={`bgMethod${idx}`} label={val[1]} checked={checked[idx]} control={<Checkbox checked={checked[idx]} onChange={(ev)=>{
					handleCheck(ev,idx)
				}}/>}/>
			})}
			</Box>
		</Box>
		if(state === 1) return <Box className="scroll-thin" sx={{width:"12rem",pl:"1rem"}}>
			<Typography sx={{textAlign:"left",mt:"0.55rem",ml:"1.91rem",mb:"0.6rem"}}>Métodos</Typography>
			{checkOptions.map((val,idx)=>{
				const loadMethod = loadingMethods[idx]
				return <Box key={`bgMethod${idx}`} sx={{display:"flex",alignItems:"center",ml:"1.44rem","> svg":{ml:"-0.25rem"}}}>
					{loadMethod > 0 && loadMethod === 1  ? <CircularProgress sx={{width:"1rem !important", height:"1rem !important"}}/> : loadMethod === 2 ? <CheckCircleIcon color="success"/> : loadMethod === 3 ? <Tooltip placement="left" arrow sx={{"> ":{color:"red"}}} title={<Typography fontSize="inherit">Ocurrió un error con este método.<br/>Haz click aquí para reintentarlo.</Typography>}><ErrorIcon sx={{cursor:"pointer"}} onClick={()=>{setLoadingMethods(vals=>{vals[idx] = 1;return [...vals]});submitFile(idx)}} color="error"/></Tooltip> : <RemoveCircleIcon sx={{color:"gray",opacity:0.33}}/>}<Typography sx={{ml: loadingMethods[idx] > 1 ? "0.74rem" : "1rem",my:"0.562rem"}}>{val[1]}</Typography>
				</Box>
			})}
		</Box>
		if(state === 2) return <Box className="scroll-thin" sx={{width:"12rem",pl:"1rem"}}>
			<FormControl>
				<FormLabel id="bg-remover-methods">Métodos</FormLabel>
				<RadioGroup aria-labelledby="bg-remover-methods" value={activeId} name="bg-remover-method" onChange={(ev)=>{
					const id = parseInt(ev.target.value)
					if(images[id]){
						setActive(images[id])
						setActiveId(id)
					}
				}}>
					{checkOptions.map((val,idx)=>{
						return <FormControlLabel key={`bgMethod${idx}`} disabled={!checked[idx] || loadingMethods[idx] > 2} value={idx} control={<Radio/>} label={val[1]}/>
					})}
				</RadioGroup>
			</FormControl>
		</Box>
	}
	function renderOptions(){
		return <Box sx={{display:"flex",flexDirection:"column",alignItems:"flex-start",height:"calc(100% - 2rem)"}}>
			{renderActions()}
			{renderMethods()}
			{/*loading && <Button sx={{mx:"auto"}} variant="contained" onClick={()=>{setLoading(false);setState(0);setLoadingMethods(vals=>{
				vals.fill(0)
				return [...vals]
			})}}>Cancelar</Button>*/}
		</Box>
	}
	function renderCanvasArea(){
		let w = "auto", h = "auto"
		if(dims){
			w = dims.w; h = dims.h
			if(w === "0"){
				w = "100%"; h = "auto"
			}
		}
		return <Box className="scroll-thin" sx={{display:"flex",alignItems:"center",overflow:"overlay",boxSizing:"border-box", width:"calc(100% - 2rem)",height:"calc(100% - 2rem)",borderRadius:"5px",flexWrap:"wrap",backgroundColor: "#eee", backgroundSize:"20px 20px", backgroundPosition:"0 0, 10px 10px",
		backgroundImage: "linear-gradient(45deg, rgba(0,0,0,0.2) 25%, transparent 25%, transparent 75%, rgba(0,0,0,0.2) 75%, rgba(0,0,0,0.2)), linear-gradient(45deg, rgba(0,0,0,0.2) 25%, transparent 25%, transparent 75%, rgba(0,0,0,0.2) 75%, rgba(0,0,0,0.2))"
		}}>
			<img width={w} height={h} src={active ? active : url} alt=""/>
		</Box>
	}
	return <Box sx={{
		transition: "0.2s ease-in-out",
		transitionProperty: "padding, opacity, width, height",
		position:"fixed",
		width: show ? "100vw" : 0,
		height: show ? "100vh" : 0,
		bgcolor: "rgba(0,0,0,0.5)",
		opacity: show ? 1 : 0,
		zIndex: 1102,
		top: 0,
		left: 0,
		pointerEvents: show ? "initial" : "none",
		p: show ? 2 : 0,
		boxSizing: "border-box"
	}}>
		<Box sx={{bgcolor:"#fff",borderRadius:2,width:"100%",height:"100%",position:"relative",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"flex-start",boxSizing:"border-box", pl: "1rem",
		}}>
			<Box sx={{display:"flex",height:"calc(100% - 4rem)",width:"100%",flexDirection:"row-reverse",alignItems:"center",justifyContent:"flex-end"}}>{renderOptions()}
			{renderCanvasArea()}
			</Box>
			<Box sx={{display:"flex",height:"3rem",position:"relative",justifyContent:"center",alignItems:"center",width:"100%"}}>
				{renderBottomOptions()}
			</Box>
		</Box>
	</Box>
}

export default BackgroundRemover

/*
		"* ::-webkit-scrollbar, > ::-webkit-scrollbar":{width:"8px",borderRadius:"3px"},
		"* ::-webkit-scrollbar-track, > ::-webkit-scrollbar-track":{backgroundColor:"rgba(0,0,100,0.1)",borderTopRightRadius:"5px",borderBottomRightRadius:"5px"},
		"* ::-webkit-scrollbar-thumb, > ::-webkit-scrollbar-thumb":{backgroundColor: "primary.main",borderRadius:"4px",border:"1px solid #fff"}
*/