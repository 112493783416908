import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import VisuallyHiddenInput from "./visuallyHiddenInput"
// import { useState } from "react"
const sideButtonSx = {"> p":{pointerEvents:"none",userSelect:"none",textOverflow:"ellipsis",whiteSpace:"nowrap",overflow:"hidden"}, cursor:"pointer", display:"flex", flexDirection: {xs:"column",md:"row"},alignItems:"center", overflow:"hidden"}
function BarButton({icon:Icon, text, onClick, onInput}){
	// const [isOver,setIsOver] = useState(false)
	const input = onInput ? <VisuallyHiddenInput type="file" accept="image/png,image/jpeg,image/bmp,image/tiff" multiple={false} onInput={onInput}/> : undefined
	return <Box sx={sideButtonSx} onClick={onClick} component={input ? "label" : "div"}>
		{input}
		<IconButton component="div" sx={{width:"fit-content"}}>{input}<Icon/></IconButton>
		<Typography fontSize="small" sx={{display:{xs:"none",md:"initial"}}}>{text[0]}</Typography>
		<Typography fontSize="small" sx={{display:{xs:"initial",md:"none"}}}>{text[1]}</Typography>
	</Box>
	/*return <Box sx={{color: "rgba(0,0,0,0.54)"}} onMouseEnter={()=>{setIsOver(true)}} onMouseLeave={()=>{setIsOver(false)}}>
		<Box sx={{transition:"0.2s linear", color: isOver ? "red" : "blue"}}><Icon/></Box>
		<Typography sx={{textDecoration:"none",pointerEvents:"none",userSelect:"none"}}>{text}</Typography>
	</Box>*/
}
export default BarButton