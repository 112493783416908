import { forwardRef, useLayoutEffect, useRef, useState } from "react"

import Box from "@mui/material/Box"
import CropIcon from "@mui/icons-material/Crop"
import SearchIcon from "@mui/icons-material/Search"
import SaveIcon from "@mui/icons-material/Save"
import RestoreIcon from "@mui/icons-material/Restore"
import CloseIcon from "@mui/icons-material/Close"
import CheckIcon from "@mui/icons-material/Check"
import Slider from "@mui/material/Slider"
import Popper from "@mui/material/Popper"
import Paper from "@mui/material/Paper"
import Grow from "@mui/material/Grow"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"

import Canvas from "./canvas"
import ActiveCrop from "./active"
import ContainedIconButton from "../../basicComps/containedIconButton"

const Crop = forwardRef(function({showCrop, onClose, zoom, setZoom, onRestore, onSave, isMobile, ctx},ref){
	const containerRef = useRef()
	const [option,setOption] = useState()
	const [crop,setCrop] = useState(false)
	const [showOption,setShowOption] = useState(false)
	const [coords,setCoords] = useState()
	const [dims,setDims] = useState({width:0,height:0,w:"0",h:"0"})
	const toggleOption = () => {
		setShowOption(!showOption)
	}
	function doCrop(){
		const canvas = ref.current
		const context = canvas.getContext("2d")
		//console.log(coords)
		if(!coords || !coords.tl) return
		let {width,height,clientWidth,clientHeight} = canvas
		//console.log({width,height,clientWidth,clientHeight})
		const xDiff = width / clientWidth
		const yDiff = height / clientHeight
		let x = coords.tl[0] * xDiff, y = coords.tl[1] * xDiff
		let w = (clientWidth - (coords.tl[0] + coords.tr[0]*-1)) * xDiff
		let h = (clientHeight - (coords.tl[1] + coords.bl[1]*-1)) * yDiff
		let sw = (clientWidth - (coords.tr[0] * -1)) * xDiff
		let sh = (clientHeight - (coords.bl[1] * -1)) * yDiff
		const imageData = context.getImageData(x,y,sw,sh)
		canvas.width = w
		canvas.height = h
		context.putImageData(imageData,0,0)
		setCoords()
	}
	const options = {
		crop: {
			ref:useRef(null),
			cb:()=>{
				setCrop(true)
				if(showOption){
					toggleOption()
				}
			},
			ico: CropIcon,
			name: "Recortar"
		},
		zoom: {
			ref:useRef(null),
			cb:()=>{
				if(!option) setOption("zoom")
				toggleOption()
			},
			render:()=>{
				return <Paper sx={{width:"12rem",px:"1rem",height:"max-content",display:"grid",boxShadow:2}}>
					<Slider
						min={0}
						max={400}
						value={zoom}
						onChange={(ev,val)=>{
							setZoom(val)
						}}/>
					<Box component="span">{zoom}%</Box>
				</Paper>
			},
			ico: SearchIcon,
			name: "Zoom"
		},
		restore: {
			cb: ()=>{
				setShowOption(false)
				setCrop(false)
				onRestore()
			},
			ico: RestoreIcon,
			name: "Deshacer"
		},
		save: {
			cb: onSave,
			ico: SaveIcon,
			name: "Guardar"
		},
		close: {
			cb: onClose,
			ico: CloseIcon,
			name: "Cerrar"
		}
	}
	function renderOptions(){
		const elems = []
		for(let key in options){
			const option = options[key]
			const Icon = option.ico
			elems.push(<Tooltip key={`${key}Option`} title={option.name} placement="top" arrow>
				<ContainedIconButton size="small" ref={option.ref} onClick={option.cb} sx={{mx: isMobile ? "0.75rem !important" : undefined}}>
					<Icon/>
					{isMobile && <Typography fontSize="small" fontWeight={600} sx={{color:"rgba(0,0,0,0.54)",position:"absolute",bottom:"-1.5rem"}}>{option.name}</Typography>}
				</ContainedIconButton>
			</Tooltip>)
		}
		return elems
	}
	
	useLayoutEffect(()=>{
		//console.log({showCrop})
		/*if(containerRef.current && ref.current && ctx) console.log({cond:true})
		else console.log({cond:false})*/
		if(containerRef.current && ref.current && ctx){
			//console.log({cond:true})
			if(dims.w !== "0" && dims.h !== "0") return
			let elem = containerRef.current
			/** @type {HTMLCanvasElement} */
			let canvas = ref.current
			let width = elem.offsetWidth
			let height = elem.offsetHeight
			//console.log({width,height,cWidth: canvas.width,cHeight: canvas.height})
			if(width === 0 || height === 0){
				if(showCrop){
					setTimeout(()=>{
						//console.log("on timeout")
						let width = containerRef.current.offsetWidth
						let height = containerRef.current.offsetWidth
						//console.log({width,height})
						if(width === 0 && height === 0) return
						setDims({width:0,height:0,w:"0",h:"0"})
					},300)
				}
				return
			}
			let proportion = width / height
			//console.log({width,height,cWidth: canvas.width,cHeight: canvas.height})
			let canvasProportion = canvas.width / canvas.height
			//console.log({proportion,canvasProportion})
			let w, h
			if(proportion > canvasProportion){
				w = "auto"
				h = "100%"
			}else{
				w = "100%"
				h = "auto"
			}
			setDims({
				width: elem.offsetWidth,
				height: elem.offsetHeight,
				w,
				h
			})
		}
		//console.log({cond:false})
	}, [ctx, ref, showCrop, dims])

	function renderCanvasArea(){
		//console.log("rendering canvas area with dims",dims)
		if(dims && dims.w !== "0"){
			let width = dims.w === "100%" ? `${zoom}%` : dims.w
			let height = dims.h === "100%" ? `${zoom}%` : dims.h
			let mx = width === "auto" ? "auto" : undefined
			let my = height === "auto" ? "auto" : undefined
			//console.log({width,height,mx,my})
			if(zoom > 100) return <Box sx={{position:"relative",width: dims.w, height: dims.h,mx,my}}>
				<Canvas sx={{width, height, mx, my}} ref={ref}/>
				{crop && <ActiveCrop zoom={zoom / 100} coords={coords} setCoords={setCoords}/>/*renderCrop()*/}
			</Box>
			return <Box sx={{position:"relative",width, height,mx,my}}>
				<Canvas sx={{width: dims.w,height: dims.h, mx, my}} ref={ref}/>
				{crop && <ActiveCrop zoom={zoom / 100} coords={coords} setCoords={setCoords}/>/*renderCrop()*/}
			</Box>
		}
		return <Box sx={{position:"relative",width:"100%",height:"100%",mx:"auto"}}>
			<Canvas sx={{width: dims.w,height: dims.h}} ref={ref}/>
			{crop && <ActiveCrop zoom={zoom / 100} coords={coords} setCoords={setCoords}/>/*renderCrop()*/}
		</Box>
	}

	return <Box sx={{
		transition: "0.2s ease-in-out",
		transitionProperty: "padding, opacity, width, height",
		position:"fixed",
		width: showCrop ? "100vw" : 0,
		height: showCrop ? "100vh" : 0,
		bgcolor: "rgba(0,0,0,0.5)",
		opacity: showCrop ? 1 : 0,
		zIndex: 1102,
		top: 0,
		left: 0,
		pointerEvents: showCrop ? "initial" : "none",
		p:showCrop ? 2 : 0,
		boxSizing: "border-box"
	}}>
		<Box sx={{bgcolor:"#fff",borderRadius:2,width:"100%",height:"100%",position:"relative",display:"flex",flexDirection:"column-reverse",alignItems:"center",justifyContent:"flex-end",boxSizing:"border-box"}}>
			<Box sx={
				{height:"100%",width:"calc(100% - 2rem)",display:"flex", overflow:"hidden",
				my:"1rem",borderRadius:2,bgcolor:"gray",boxSizing:"border-box", position:"relative",alignItems: "center", justifyItems: "center"}}>
				<Box sx={{display:"flex", justifyContent:"center",width:`100%`,mx:"auto",height:"100%", overflow:"auto"}} ref={containerRef}>
					{renderCanvasArea()}
				</Box>
			</Box>
			<Box sx={{mt:1,mb: isMobile ? "1rem" : undefined, "& button":{mx:0.5}}}>
				<Popper sx={{zIndex:1}}
					open={showOption}
					anchorEl={options[option]?.ref.current}
					role={undefined}
					placement="bottom"
					transition
					disablePortal>
						{({TransitionProps, placement})=>(
							<Grow {...TransitionProps} style={{
								transformOrigin: placement === "bottom" ? "top" : "bottom"
							}}>
								{options[option].render()}
							</Grow>
						)}
				</Popper>
				{crop ? <>
					<ContainedIconButton size="small" onClick={()=>{setCrop(!crop);doCrop()}}><CheckIcon/></ContainedIconButton>
					<ContainedIconButton size="small" onClick={()=>{setCrop(!crop)}}><CloseIcon/></ContainedIconButton>
				</> : renderOptions()}
				
			</Box>
		</Box>
	</Box>
})

export default Crop