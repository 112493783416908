import IconButton from "@mui/material/IconButton"
import styled from "@mui/material/styles/styled"
const ContainedIconButton = styled(IconButton)(({theme})=>({
	transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",//"all 0.25s ease-in-out",
	backgroundColor: theme.palette.primary.main,
	//border: "1px solid white",
	color: "#fff",
	boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
	"&.MuiIconButton-root:hover":{
		color: "#fff",//theme.palette.primary.main,
		backgroundColor: theme.palette.primary.dark,
		borderColor: theme.palette.primary.main,
		boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
	}
}))
export default ContainedIconButton