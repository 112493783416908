import React, { createContext, useState } from 'react'
export const Context = createContext()

const Provider = ({children})=>{
	const [auth,setAuth] = useState(()=>{
		return window.sessionStorage.getItem("tkn")
	})
	const [userInfo,setUserInfo] = useState(()=>{
		return JSON.parse(window.sessionStorage.getItem("usrInfo"))
	})
	const [redirect,setRedirect] = useState("")
	const val = {
		auth,
		userInfo,
		redirect,
		setAuth: (token)=>{
			setAuth(token)
			window.sessionStorage.setItem("tkn",token)
		},
		unsetAuth:()=>{
			setAuth(undefined)
			setUserInfo(undefined)
			window.sessionStorage.removeItem("tkn")
			window.sessionStorage.removeItem("usrInfo")
		},
		setRedirect:(val)=>{
			setRedirect(val)
		},
		setUserInfo: (data)=>{
			setUserInfo(data)
			window.sessionStorage.setItem("usrInfo",JSON.stringify(data))
		}
	}

	return <Context.Provider value={val}>
		{children}
	</Context.Provider>
}

const obj = {
	Provider,
	Consumer: Context.Consumer
}

export default obj;