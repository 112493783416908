import { useContext, useEffect, useState } from "react"
import { Context } from "../../contexts/general"
import Create from "./create"
import http from "../../libs/http"
import { useNavigate, useSearchParams } from "react-router-dom"
import Editor from "../editor"
import View from "./view"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import errorHandler from "../../libs/statusHandler"

function Temporal(){
	const {auth,redirect,setRedirect} = useContext(Context)
	const [queryParams] = useSearchParams()
	const [screen,setScreen] = useState({val: 0})
	const [err,setErr] = useState()
	const navigate = useNavigate()
	useEffect(()=>{
		if(!auth && redirect){
			setRedirect()
			return navigate(redirect,{replace:true})
		}
		if(!queryParams){
			console.log("No query params")
			return
		}
		let tid = queryParams.get("tid")
		let sid = queryParams.get("sid")
		console.log({tid,sid})
		if(!tid || !sid){
			if(auth) return
			return setErr(`Los parámetros "tid" y "sid" son requeridos`)
		}
		http.get({url:`rectpack/get_canvas/${tid}?sid=${sid}`}).then(res=>{
			console.log(res)
			if(res.status === 200){
				if(res.data === "Pending"){
					return setScreen({val: 1})
				}
				return setScreen({val: 2, data: res.data})
			}
			if(res.status === 404 && res.data === "temp") return setErr("No se encontró el proceso")
			return setErr(errorHandler(res.status))
		})
	}, [queryParams, auth, redirect, navigate, setRedirect])
	if(auth) return <Create/>
	if(screen.val === 1) return <Editor reset={()=>{
		setScreen({val: 0})
		console.log("setting screen to 0")
		let tid = queryParams.get("tid")
		let sid = queryParams.get("sid")
		http.get({url:`rectpack/get_canvas/${tid}&sid=${sid}`}).then(res=>{
			console.log(res)
			if(res.status === 200){
				if(res.data === "Pending"){
					return setScreen({val: 1})
				}
				return setScreen({val: 2, data: res.data})
			}
			if(res.status === 404 && res.data === "temp") return setErr("No se encontró el proceso")
			return setErr(errorHandler(res.status))
		})
	}}/>
	if(screen.val === 2) return <Box sx={{display:"flex",height:"fit-content",mt:"0.5rem",px:"0.5rem",width:"100%",boxSizing:"border-box",justifyContent:"center"}}>
		<View data={screen.data}/>
	</Box>
	return <Box sx={{display:"flex", height:"100%", pb: "0.5rem", boxSizing:"border-box", flexDirection:"column", justifyContent: "center", alignItems:"center"}}>
		{err ? (typeof err === "string" ? <Typography color="error">{err}</Typography> : <Box></Box>) : <CircularProgress/>}
	</Box>
}
export default Temporal