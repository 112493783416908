import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import http from "../libs/http"
import Paper from "@mui/material/Paper"
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import validatePassword from "./scripts/validatePassword"
function ConfirmSignUp(){
	const location = useLocation()
	const isRestore = location.pathname === "/restore_password" ? true : false
	const search = new URLSearchParams(location.search)
	const userId = search.get("_id")
	const token = search.get("tkn")
	const isTS = search.get("ts")
	const [done,setDone] = useState(false)
	const [loading,setLoading] = useState(false)
	const [error,setError] = useState()
	const [renew,setRenew] = useState(false)
	const [password,setPassword] = useState("")
	const [showPassword,setShowPassword] = useState(false)
	const [pwError,setPwError] = useState("")
	const [confirmPassword,setConfirmPassword] = useState("")
	const [showConfirmPassword,setShowConfirmPassword] = useState(false)
	const [cpwError,setCpwError] = useState("")
	useEffect(()=>{
		if(loading || done || renew || error || isRestore) return
		if(isTS) return
		setLoading(true)
		http.post({url:"users/user/confirm_signup",body:{_id: userId, tkn: token}}).then(res=>{
			const data = res.data
			setLoading(false)
			if(data.msg === "Time limit exceeded") return setDone("expired")
			if(data.msg === "Confirmation not found") return setDone("notfound")
			if(res.status === 200) return setDone(true)
			setError(data.msg)
		}).catch(err=>{
			console.error(err)
			setError(err.message)
			setLoading(false)
		})
	}, [loading, userId, token, done, renew, error])
	function getDoneComp(){
		if(done === "expired") return <><Typography>Expirado</Typography><Typography>La confirmación expiró, por favor renuévala para continuar</Typography>
		<Button disabled={loading} sx={{mx:"auto",position:"relative",width:"max-content"}} variant="contained" onClick={()=>{
			setLoading(true)
			if(error) setError()
			http.post({url:"users/user/renovate_cfm",body:{_id: userId, tkn: token}}).then(res=>{
				setLoading(false)
				setDone(false)
				if(res.status === 200) return setRenew(true)
				setError(res.data.msg ? res.data.msg : res.statusText)
			}).catch(err=>{
				console.error(err)
				setError(err.message)
				setLoading(false)
			})
		}}>Renovar</Button></>
		if(done === "notfound") return <Typography>Esta solicitud no es válida o no se puede usar</Typography>
		return <><Typography>{isRestore ? "Contraseña cambiada" : "Usuario confirmado"}</Typography><Typography>Ya puedes iniciar sesión en tu cuenta</Typography><Button href="/rectpack/signin" variant="contained" sx={{width:"max-content",mx:"auto", mt:2}}>Ir al inicio</Button></>
	}
	return <Box sx={{backgroundColor:"rgba(0,0,0,0.1)",transition: "0.2s linear",height:"100%",my:"auto",boxSizing:"border-box",justifyContent:"center",display:"flex",flexDirection:"column",p:2
		,"> *":{mb:2}
	}}>
		{error && <Typography color="error">{error}</Typography>}
		{renew && <><Typography>Nueva confirmación generada, revisa tu correo</Typography><Button href="/rectpack/signin" variant="contained" sx={{width:"max-content",mx:"auto", mt:2}}>Ir al inicio</Button></>}
		{done && getDoneComp()}
		{loading && <CircularProgress sx={{mx:"auto"}}/>}
		{(isTS || isRestore) && (!loading && !done && !renew) && <Box component={Paper} sx={{display:"flex",flexDirection:"column",p:2}}>
			<Typography sx={{mb:1}}>Ingresa tu nueva contraseña{isTS && " para finalizar el registro"}</Typography>
			<TextField size="small" label="Contraseña" sx={{mb:1}} id="password" type={showPassword ? "text" : "password"} variant="standard"
			InputProps={{endAdornment:(<InputAdornment position="end"><IconButton size="small" onClick={()=>{setShowPassword(!showPassword)}} tabIndex={-1}>{showPassword ? <VisibilityOff/> : <Visibility/>}</IconButton></InputAdornment>)}}
			value={password} onInput={(ev)=>{
				const val = ev.target.value
				if(!validatePassword(val)) setPwError(["La contraseña no cumple con las condiciones de seguridad:",["Debe tener una longitud mínima de 8","Debe tener al menos una mayúscula y una minúscula","Debe tener al menos un número","Debe tener al menos uno de estos carácteres especiales: #?!@$%^&*-"]])
				else setPwError()
				if(confirmPassword){
					if(val !== confirmPassword) setCpwError("Las contraseñas no coinciden")
					else setCpwError()
				}
				setPassword(val)
			}}
			/>
			{pwError && <Box sx={(theme)=>({display:"flex",flexDirection:"column", width:"max-content",mx:"auto", "> ul":{
				display:"flex",flexDirection:"column",alignItems:"flex-start",color: theme.palette.error.main
			}
			})}>
				<Typography color="error">{pwError[0]}</Typography>
				<ul>{pwError[1].map((val,idx) => {
					return <li key={`err${idx}`}><Typography color="error">{val}</Typography></li>
				})}
				</ul>
			</Box>}
			<TextField size="small" label="Confirmar contraseña" sx={{mb:1}} id="cpassword" type={showConfirmPassword ? "text" : "password"} variant="standard"
			InputProps={{endAdornment:(<InputAdornment position="end"><IconButton size="small" onClick={()=>{setShowConfirmPassword(!showConfirmPassword)}} tabIndex={-1}>{showConfirmPassword ? <VisibilityOff/> : <Visibility/>}</IconButton></InputAdornment>)}}
			value={confirmPassword} onInput={(ev)=>{
				const val = ev.target.value
				if(val !== password) setCpwError("Las contraseñas no coinciden")
				else setCpwError()
				setConfirmPassword(ev.target.value)
			}}
			/>
			{cpwError && <Typography color="error">{cpwError}</Typography>}
			<Button sx={{width:"max-content",mx:"auto", mt:2, position:"relative"}} variant="contained" onClick={()=>{
				if(!pwError && !cpwError){
					setLoading(true)
					if(error) setError()
					const body = {
						_id: userId,
						tkn: token
					}
					if(isRestore) body.npw = password
					else body.pw = password
					http.post({url:isRestore ? "users/user/confirm_pw_restore" : "users/user/confirm_tsignup",body}).then(res=>{
						const data = res.data
						setLoading(false)
						if(data.msg === "Time limit exceeded") return setDone("expired")
						if(data.msg === "Confirmation not found" || data.msg === "Recovery not found" || data.msg === "User not found") return setDone("notfound")
						if(res.status === 200) return setDone(true)
						setError(data.msg)
					}).catch(err=>{
						console.error(err)
						setError(err.message)
						setLoading(false)
					})
				}
			}}>Confirmar{loading && <CircularProgress sx={{mx:"auto",position:"absolute"}}/>}</Button>
		</Box>}
	</Box>
}
export default ConfirmSignUp